import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DialogContent, Grid, InputAdornment, Typography } from '@mui/material';
import LabelTextFieldGrid from 'components/MissionFollowUp/LabelTextFieldGrid';
import PolyDialog from 'components/MUIOverload/PolyDialog';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import {
  PurchaseOrderNode,
  useCreateOrUpdateCompensationMutation,
} from 'generated/graphql';
import { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { POLY_DATE_MONTH } from 'poly-constants';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { graphQlDateFormatter } from 'utils';

interface CommentaryModalProps {
  open: boolean;
  closeModal?: () => void;
  currentPo?: PurchaseOrderNode;
  month: Moment;
  comment: string;
  formName: string;
}

const CommentModal = (properties: CommentaryModalProps) => {
  const { isMissionEditable } = useTableContext();
  const { register, getValues, watch, setValue } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const [updateCompensation] = useCreateOrUpdateCompensationMutation({
    onCompleted: () => {
      enqueueSnackbar('Commentaire enregistré', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar("Erreur lors de l'enregistrement du commentaire", {
        variant: 'error',
      });
    },
  });
  const formNameComment = `${properties.formName}.comment`;

  const onSaveClick = async (
    properties: CommentaryModalProps,
    commentValue: string
  ) => {
    await updateCompensation({
      variables: {
        month: graphQlDateFormatter(properties.month.startOf('month')),
        purchaseOrderId: properties.currentPo?.id || '',
        comment: commentValue,
        amount: getValues(`${properties.formName}.amount`),
      },
    });

    if (properties.closeModal) {
      properties.closeModal();
    }
  };

  const cancel = () => {
    setValue(formNameComment, properties.comment);
    if (properties.closeModal) {
      properties.closeModal();
    }
  };

  return (
    <PolyDialog
      fullWidth={true}
      open={properties.open}
      onClose={properties.closeModal}
      disableEnforceFocus={true}
    >
      <DialogContent>
        <Typography
          sx={{ mb: 1, color: 'primary.main' }}
          variant="h3Bold"
          component="h3"
        >
          Justificatif de compensation
        </Typography>
        <Grid container spacing={0}>
          <Grid container item xs={12}>
            <LabelTextFieldGrid
              disabled
              title="Nom du bon de commande"
              sizegrid={6}
              defaultValue={properties.currentPo?.name}
            />
            <LabelTextFieldGrid
              disabled
              title="Mois facturé"
              sizegrid={6}
              defaultValue={properties.month.format(POLY_DATE_MONTH)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayIcon fontSize={'small'} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid container spacing={0}>
            <LabelTextFieldGrid
              disabled={!isMissionEditable}
              title="Commentaire"
              sizegrid={12}
              multiline
              rows={4}
              defaultValue={properties.comment}
              inputRef={register({ required: true })}
              name={formNameComment}
            />
          </Grid>
        </Grid>
        <PolyFooter>
          <PongoButton onClick={cancel}>Annuler</PongoButton>

          <PongoButton
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            disabled={!watch(formNameComment) || !isMissionEditable}
            onClick={() => {
              onSaveClick(properties, getValues(formNameComment));
            }}
          >
            Enregistrer
          </PongoButton>
        </PolyFooter>
      </DialogContent>
    </PolyDialog>
  );
};

export default CommentModal;
