import { TableBody } from '@mui/material';
import { useFilterContext } from 'components/commons/FilterContext/FilterContextProvider';
import {
  totalBilledPoForMonth,
  transformToPurchaseOrderRevenue,
} from 'components/Revenue/BilledRevenueTables/BilledRevenueMissionTable/RevenueTableBodyByPo';
import {
  TableMode,
  useTableContext,
} from 'components/Revenue/RevenueGlobalTable/context/TableContextProvider';
import ClientRow from 'components/Revenue/RevenueGlobalTable/RevenueRows/ClientRow';
import TotalRow from 'components/Revenue/RevenueGlobalTable/RevenueRows/TotalRow';
import RevenueTableEmptyRow from 'components/Revenue/Tables/RevenueTableEmptyRow';
import {
  BillingActivityInformationNode,
  BillingClientNode,
  PurchaseOrderNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, { useCallback, useMemo } from 'react';
import { Transition } from 'react-transition-group';
import { useSelector } from 'store';

import { isInRange } from '../Tables/util';
import {
  getAmountPerMonthForActivities,
  getPoAmountPerMonths,
} from '../utils/utils';
import MissionRow from './RevenueRows/MissionRow';

export interface BillingClientRevenue {
  client: BillingClientNode;
  billingMissions: BillingActivityInformationRevenue[];
  dateRange: { start: Moment; end: Moment };
  totals: { amount: number; month: Moment }[];
  total: number;
}

export interface BillingActivityInformationRevenue {
  billingMission: BillingActivityInformationNode;
  missionEnd: Moment;
  totals: { amount: number; month: Moment }[];
  total: number;
}

function getTotalPoForMonth(
  po: PurchaseOrderNode,
  month: Moment,
  mode: TableMode
): number {
  switch (mode) {
    case TableMode.BILLED:
      return totalBilledPoForMonth(po, month, true);
    case TableMode.ACTUAL: {
      const totalRevenue = _.sumBy(po.revenues, (revenue) =>
        month.isSame(moment(revenue.month), 'month') ? revenue.amount : 0
      );
      const totalCompensation = _.sumBy(po.compensations, (compensation) =>
        month.isSame(moment(compensation.month), 'month')
          ? compensation.amount
          : 0
      );
      return totalRevenue + totalCompensation;
    }
    default:
      return 0;
  }
}

function getTotalMissionForYear(
  billingMission: BillingActivityInformationNode,
  mode: TableMode,
  firstDisplayedMonth: Moment
): number {
  switch (mode) {
    case TableMode.ACTUAL: {
      return _(billingMission.purchaseOrders)
        .map((po) => {
          return (
            _.sumBy(po.revenues, (revenue) =>
              moment(revenue.month).isSame(firstDisplayedMonth, 'year')
                ? revenue.amount
                : 0
            ) +
            _.sumBy(po.compensations, (compensation) =>
              moment(compensation.month).isSame(firstDisplayedMonth, 'year')
                ? compensation.amount
                : 0
            )
          );
        })
        .sum();
    }
    default:
      return 0;
  }
}

export const getMaxBillingDateAndEndForPos = (
  pos: PurchaseOrderNode[],
  end: moment.Moment
): moment.Moment => {
  const billingDateValues = _.flatMap(pos, (po) => {
    return _.flatMap(po.bills, (bill) => {
      return bill.billingDate ? moment(bill.billingDate) : [];
    });
  });
  return _.max([...billingDateValues, end]) || end;
};

export const getMaxBillingDateAndEndForMissions = (
  billingMissions: BillingActivityInformationRevenue[],
  end: moment.Moment
): moment.Moment => {
  const billingDateValues = _.map(billingMissions, (billingMission) => {
    return getMaxBillingDateAndEndForPos(
      billingMission.billingMission.purchaseOrders || [],
      end
    );
  });
  return _.max([...billingDateValues, end]) || end;
};

function getMaxDateForMission(
  billingMission: BillingActivityInformationNode,
  mode: TableMode
) {
  switch (mode) {
    case TableMode.ACTUAL:
      return billingMission.activity?.expirationDate;
    case TableMode.BILLED:
      return getMaxBillingDateAndEndForPos(
        billingMission.purchaseOrders || [],
        billingMission.activity?.expirationDate
      );
    default:
      return billingMission.activity?.expirationDate;
  }
}

function getMaxDateForMissions(
  billingMissionRevenues: BillingActivityInformationRevenue[],
  mode: TableMode
) {
  const latestMissionEnd = _.maxBy(
    billingMissionRevenues,
    (bm) => bm.billingMission.activity?.expirationDate
  );

  const maxDate = moment(
    latestMissionEnd?.billingMission.activity?.expirationDate
  );

  switch (mode) {
    case TableMode.ACTUAL:
      return maxDate;
    case TableMode.BILLED:
      return getMaxBillingDateAndEndForMissions(
        billingMissionRevenues,
        moment(latestMissionEnd?.billingMission.activity?.expirationDate)
      );
    default:
      return maxDate;
  }
}

export default function RevenueTableBodyByClient() {
  const { billingMissions, displayedMonths, mode, dateRange } =
    useTableContext();
  const { filterNotValidatedRevenue } = useFilterContext();
  const selectedMissions = useSelector(
    (state) => state.activity.selectedMissions
  );
  const amountPerMonthsForActivities = useMemo(() => {
    return getAmountPerMonthForActivities(billingMissions, dateRange.start);
  }, [billingMissions, dateRange.start]);
  const transformBillActivityInformation = useCallback(
    (
      billingMission: BillingActivityInformationNode
    ): BillingActivityInformationRevenue => {
      let totals = [] as { amount: number; month: moment.Moment }[];
      let totalMissionPerYear = 0;
      if (mode === TableMode.BILLED) {
        const numberOfDisplayedMonths = displayedMonths.length;
        const amountPerClientPerMonths = new Array(
          numberOfDisplayedMonths
        ).fill(0);
        const poRevenues = _.map(billingMission.purchaseOrders, (po) =>
          transformToPurchaseOrderRevenue(po, displayedMonths)
        );
        _.forEach(poRevenues, (po) => {
          const amountPerDisplayedMonths = getPoAmountPerMonths(
            po.po,
            displayedMonths,
            numberOfDisplayedMonths
          );
          amountPerDisplayedMonths.forEach(
            (amount, index) => (amountPerClientPerMonths[index] += amount)
          );
        });

        const total = _.sum(
          amountPerMonthsForActivities.find(
            (amountPerMonthsForActivity) =>
              amountPerMonthsForActivity.activity?.id ===
              billingMission.activity?.id
          )?.amounts
        );

        totalMissionPerYear = total ? total : 0;
        totals = _.map(amountPerClientPerMonths, (amount, index) => {
          return { amount: amount, month: displayedMonths[index] };
        });
      } else {
        totalMissionPerYear = getTotalMissionForYear(
          billingMission,
          mode,
          displayedMonths[0]
        );
        totals = _.map(displayedMonths, (month) => {
          const totalPosForMonth = _.sumBy(
            billingMission.purchaseOrders,
            (po) => {
              return getTotalPoForMonth(po, month, mode);
            }
          );
          return {
            amount: totalPosForMonth,
            month: month,
          };
        });
      }

      return {
        billingMission: billingMission,
        missionEnd: getMaxDateForMission(billingMission, mode),
        totals: totals,
        total: totalMissionPerYear,
      };
    },
    [displayedMonths, mode, amountPerMonthsForActivities]
  );

  const transformBillingClient = useCallback(
    (
      client: BillingClientNode,
      billingMissions: BillingActivityInformationRevenue[]
    ): BillingClientRevenue => {
      let totalBillsYear = 0;
      _.forEach(billingMissions, (bm) =>
        _.forEach(bm.billingMission.purchaseOrders, (po) => {
          totalBillsYear += _.sumBy(
            po.bills?.filter((bill) =>
              moment(bill.billingDate).isSame(dateRange.start, 'year')
            ),
            (bill) => bill.total / bill.currencyConversionRate
          );
        })
      );
      const totalClient =
        mode === TableMode.BILLED
          ? totalBillsYear
          : _.sumBy(billingMissions, (bm) => bm.total);
      const totals = _.map(displayedMonths, (month) => {
        const totalMissionsFormMonth = _(billingMissions)
          .map(
            (billingMission) =>
              _.find(billingMission.totals, (total) =>
                total.month.isSame(month, 'month')
              )?.amount || 0
          )
          .sum();
        return {
          amount: totalMissionsFormMonth,
          month: month,
        };
      });

      const missionStart = _.minBy(
        billingMissions,
        (bm) => bm.billingMission.activity?.startDate
      );

      const missionEnd = getMaxDateForMissions(billingMissions, mode);

      return {
        client: client,
        billingMissions: billingMissions,
        dateRange: {
          start: moment(missionStart?.billingMission.activity?.startDate),
          end: missionEnd,
        },
        totals: totals,
        total: totalClient,
      };
    },
    [displayedMonths, mode, dateRange.start]
  );

  const clients = useMemo(() => {
    const billMissionsRevenues = _.map(
      billingMissions,
      transformBillActivityInformation
    );
    const billingMissionsByClientDict = _.groupBy(
      billMissionsRevenues,
      (bm) => bm.billingMission.billingClient?.id
    );
    const clients = _(billingMissionsByClientDict)
      .flatMap((billingMissions) => {
        const client = billingMissions[0].billingMission
          .billingClient as BillingClientNode;
        if (client) {
          return transformBillingClient(client, billingMissions);
        }
        return [];
      })
      .orderBy((client) => client.client.corporateName)
      .value();
    return clients;
  }, [
    billingMissions,
    transformBillActivityInformation,
    transformBillingClient,
  ]);

  const isValidated = (purchaseOrders?: PurchaseOrderNode[]) => {
    let hasOneValidPo = false;
    for (const purchaseOrder of purchaseOrders || []) {
      if (
        !isInRange(
          purchaseOrder.periodBeginning,
          purchaseOrder.periodEnding,
          moment()
        )
      ) {
        continue;
      }
      if (
        purchaseOrder.revenues &&
        _.some(
          purchaseOrder.revenues,
          (revenue) =>
            moment(revenue.month).isSame(moment(), 'month') && revenue.validated
        )
      ) {
        hasOneValidPo = true;
      } else {
        return false;
      }
    }
    return hasOneValidPo;
  };
  const clientsWithNonValidatedMission = Object.assign(
    {},
    ..._.map(clients, (client) => {
      const notValidatedMissions = _.filter(
        client.billingMissions,
        (billingMission) => {
          return (
            (!isValidated(billingMission.billingMission.purchaseOrders) ||
              billingMission.billingMission.purchaseOrders?.length === 0) &&
            moment().isBetween(
              billingMission.billingMission.activity?.startDate,
              billingMission.missionEnd,
              'month',
              '[]'
            )
          );
        }
      );
      if (notValidatedMissions.length > 0) {
        return {
          [client.client.id]: notValidatedMissions,
        };
      }
    })
  );

  return (
    <TableBody>
      {_.map(clients, (clientRevenue) => {
        const notValidatedMissions =
          clientRevenue.client.id in clientsWithNonValidatedMission
            ? clientsWithNonValidatedMission[clientRevenue.client.id]
            : undefined;
        const highlightNotValidatedMonth =
          mode === TableMode.ACTUAL && notValidatedMissions;
        if (
          !filterNotValidatedRevenue ||
          clientRevenue.client.id in clientsWithNonValidatedMission
        ) {
          const maxDate = getMaxBillingDateAndEndForMissions(
            clientRevenue.billingMissions,
            clientRevenue.dateRange.end
          );
          const isDisplayed = displayedMonths[0].isBetween(
            clientRevenue.dateRange.start,
            maxDate,
            'year',
            '[]'
          );
          return (
            <Transition
              mountOnEnter
              unmountOnExit
              timeout={250}
              key={`client-${clientRevenue.client.id}`}
              in={isDisplayed}
            >
              {(status) => {
                return (
                  <>
                    {selectedMissions.length > 0 ? (
                      <>
                        {_.map(clientRevenue.billingMissions, (mission) => {
                          if (
                            !filterNotValidatedRevenue ||
                            _.some(
                              notValidatedMissions,
                              (notValidatedMission) =>
                                notValidatedMission.billingMission.id ===
                                mission.billingMission.id
                            )
                          ) {
                            const amountPerMonthForActivity =
                              amountPerMonthsForActivities?.find(
                                ({ activity }) =>
                                  activity?.id ===
                                  mission.billingMission.activity?.id
                              );
                            return (
                              <MissionRow
                                billingMission={mission}
                                currentMonthNotValidated={
                                  _.some(
                                    notValidatedMissions,
                                    (mission) => mission === mission
                                  ) && !!highlightNotValidatedMonth
                                }
                                status={status}
                                key={`mission-${mission.billingMission.id}`}
                                amountPerMonthForActivity={
                                  amountPerMonthForActivity
                                }
                              />
                            );
                          }
                        })}
                      </>
                    ) : (
                      <ClientRow
                        clientRevenue={clientRevenue}
                        billingMissionsRevenue={clientRevenue.billingMissions}
                        filterNotValidatedRevenue={filterNotValidatedRevenue}
                        notValidatedMissions={
                          clientRevenue.client.id in
                          clientsWithNonValidatedMission
                            ? clientsWithNonValidatedMission[
                                clientRevenue.client.id
                              ]
                            : undefined
                        }
                        status={status}
                        amountPerMonthForActivities={
                          amountPerMonthsForActivities
                        }
                        highlightNotValidatedMonth={highlightNotValidatedMonth}
                      />
                    )}
                  </>
                );
              }}
            </Transition>
          );
        }
      })}
      {_.isEmpty(clients) && (
        <RevenueTableEmptyRow emptyText={'Aucune mission déclarée'} />
      )}
      <TotalRow
        clientRevenues={clients}
        amountPerMonthForActivities={amountPerMonthsForActivities}
      />
    </TableBody>
  );
}
