import { Box, Divider, Stack, Typography } from '@mui/material';
import {
  getEstimatedMonthly,
  getRefDate,
  getTotalRevenueForRange,
} from 'components/Revenue/Estimated/utils';
import DisplayDirector from 'components/Revenue/Tables/DisplayDirector';
import {
  ActivityNode,
  BillingPurchaseOrderCurrencyChoices,
  RevenueObjectiveNode,
} from 'generated/graphql';
import { Moment } from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { useMemo } from 'react';

interface EstimatedRevenueSumUpProps {
  activity?: ActivityNode;
  objective?: RevenueObjectiveNode;
  currentYear: Moment;
}

export const EstimatedRevenueSumUp = (props: EstimatedRevenueSumUpProps) => {
  const { activity, objective, currentYear } = props;
  const referenceDate = getRefDate(currentYear);

  const getActualTotal = useMemo(() => {
    if (!activity) {
      return 0;
    }
    return getTotalRevenueForRange(
      currentYear.clone().startOf('year'),
      referenceDate.clone().subtract(1, 'month'),
      activity
    );
  }, [activity, currentYear, referenceDate]);

  const getActualToBeDone = (objective: RevenueObjectiveNode) => {
    const result = objective.objective - getActualTotal;
    return result < 0 ? 0 : result;
  };

  return (
    <Box sx={{ width: 'fit-content' }}>
      <Divider sx={{ mb: 2 }} />
      <Stack direction={'row'}>
        {activity && <DisplayDirector activity={activity} />}
        <Stack direction={'row'} sx={{ ml: 4 }} alignItems={'center'}>
          <Typography color={'textSecondary'}>Réalisé à date :</Typography>
          <Typography ml={1} fontWeight={'bold'}>
            {totalFormat(
              getActualTotal,
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          </Typography>
        </Stack>
        {!!objective && (
          <React.Fragment>
            <Stack direction={'row'} sx={{ ml: 4 }} alignItems={'center'}>
              <Typography color={'textSecondary'}>Reste à faire :</Typography>
              <Typography ml={1} fontWeight={'bold'}>
                {totalFormat(
                  getActualToBeDone(objective),
                  BillingPurchaseOrderCurrencyChoices.Eur
                )}
              </Typography>
            </Stack>
            <Stack direction={'row'} sx={{ ml: 4 }} alignItems={'center'}>
              <Typography color={'textSecondary'}>
                Objectif mensuel restant :
              </Typography>
              <Typography ml={1} fontWeight={'bold'}>
                {totalFormat(
                  getEstimatedMonthly(
                    objective,
                    getActualTotal,
                    referenceDate,
                    activity
                  ),
                  BillingPurchaseOrderCurrencyChoices.Eur
                )}
              </Typography>
            </Stack>
          </React.Fragment>
        )}
      </Stack>
      <Divider sx={{ mt: 2 }} />
    </Box>
  );
};
