import { Theme } from '@mui/material';
import {
  ActivityNode,
  EmployeeNode,
  MeEmployeeFragment,
  RevenueObjectiveNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import {
  isEmployeeChiefOfActivity,
  isEmployeeDirectorOfActivity,
  OpacitySuffix,
} from 'utils';

export const getMonthsWithActualRevenue = (activity: ActivityNode) => {
  const allRevenuesForActivities = _.flatMap(
    activity.billingInformation?.purchaseOrders,
    (po) => po.revenues || []
  );
  const allRevenuesMonths = _.map(allRevenuesForActivities, 'month');

  const allCompensationsForActivities = _.flatMap(
    activity.billingInformation?.purchaseOrders,
    (po) => po.compensations || []
  );
  const allCompensationsMonths = _.map(allCompensationsForActivities, 'month');

  return _.uniq(_.concat(allRevenuesMonths, allCompensationsMonths));
};

export const getEstimatedMonthly = (
  objective: RevenueObjectiveNode,
  actualTotal: number,
  refDate: Moment,
  activity?: ActivityNode,
  estimatedTotal = 0,
  filledEstimated = 0
) => {
  if (!activity) {
    return 0;
  }
  const currentYear = moment(objective.year, 'YYYY');
  const endDate = moment
    .min(currentYear.clone().endOf('year'), moment(activity.expirationDate))
    .endOf('month');
  const startDate = moment
    .max([
      refDate,
      moment(activity.startDate),
      moment(`${objective.year}-01-01`),
    ])
    .startOf('month');

  const monthsWithActualRevenue = _.map(
    getMonthsWithActualRevenue(activity),
    (month) => moment(month)
  );
  const futureMonthsWithActualRevenue = _.filter(
    monthsWithActualRevenue,
    (month) => month.isSameOrAfter(startDate)
  );

  const monthCount =
    endDate.diff(startDate, 'month') + 1 - futureMonthsWithActualRevenue.length;
  if (monthCount <= 0) {
    return 0;
  }
  const inputsToFill = monthCount - filledEstimated;
  if (inputsToFill <= 0) {
    return 0;
  }
  const result =
    (objective.objective - actualTotal - estimatedTotal) / inputsToFill;
  return result < 0 ? 0 : result;
};

export const getTotalRevenueForRange = (
  start: Moment,
  end: Moment,
  mission: ActivityNode
) => {
  if (end.isBefore(start, 'month')) {
    return 0;
  }
  const allRevenuesForActivities = _.flatMap(
    mission.billingInformation?.purchaseOrders,
    (po) => po.revenues || []
  );

  const allCompensationsForActivities = _.flatMap(
    mission.billingInformation?.purchaseOrders,
    (po) => po.compensations || []
  );

  const revenueTotal = _.sumBy(allRevenuesForActivities, (revenue) => {
    if (moment(revenue.month).isBetween(start, end, 'month', '[]')) {
      return revenue.amount;
    }
    return 0;
  });

  const compensationTotal = _.sumBy(
    allCompensationsForActivities,
    (compensation) => {
      if (moment(compensation.month).isBetween(start, end, 'month', '[]')) {
        return compensation.amount;
      }
      return 0;
    }
  );

  return revenueTotal + compensationTotal;
};

// useful to know where is the estimated start
export const getRefDate = (currentYear: Moment) => {
  const today = moment();
  if (today.isSame(currentYear, 'year')) {
    return today;
  }
  if (today.isBefore(currentYear, 'year')) {
    return currentYear.clone().startOf('year');
  } else {
    // estimated start doesn't exist in the past so returning a non-displayable date
    return currentYear.clone().add(1, 'year').startOf('year');
  }
};

export const findActualAmountOrUndefined = (
  month: Moment,
  activity: ActivityNode
): number | undefined => {
  const allRevenuesForActivities = _.flatMap(
    activity.billingInformation?.purchaseOrders,
    (po) => po.revenues || []
  );
  const allCompensationsForActivities = _.flatMap(
    activity.billingInformation?.purchaseOrders,
    (po) => po.compensations || []
  );

  const monthRevenues = _.filter(allRevenuesForActivities, (revenue) =>
    month.isSame(moment(revenue.month), 'month')
  );
  const monthCompensations = _.filter(
    allCompensationsForActivities,
    (compensation) => month.isSame(moment(compensation.month), 'month')
  );
  const noRevenuesFound =
    _.isEmpty(monthRevenues) && _.isEmpty(monthCompensations);
  if (noRevenuesFound) {
    return undefined;
  }

  const revenueTotal = _.sumBy(monthRevenues, 'amount');
  const compensationTotal = _.sumBy(monthCompensations, 'amount');

  return revenueTotal + compensationTotal;
};

export const findEstimatedAmountForMonth = (
  month: Moment,
  mission: ActivityNode
) => {
  const revenueEstimation = _.find(mission.revenueEstimations, (estimation) =>
    month.isSame(moment(estimation.month))
  );
  return revenueEstimation;
};

export const verifyEmployeePermissions = (
  mission: ActivityNode | undefined | null,
  employee: EmployeeNode | MeEmployeeFragment | undefined | null
) => {
  if (!employee || !mission) {
    return {
      isDirector: false,
      isChief: false,
    };
  }
  const isDirector = isEmployeeDirectorOfActivity(mission, employee);
  const isChief = isEmployeeChiefOfActivity(mission, employee);
  return {
    isDirector,
    isChief,
  };
};

export const actualRevenueBackgroundColorSx = {
  backgroundColor: 'background.default',
};

export const estimatedRevenueBackgroundColorSx = {
  backgroundColor: (theme: Theme) =>
    theme.palette.info.main + OpacitySuffix.TEN_PERCENT,
};
