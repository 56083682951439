import { Grid } from '@mui/material';
import ExpandableCard from 'components/commons/ExpandableCard/ExpandableCard';
import SummaryInfosDisplay from 'components/commons/SummaryInfosDisplay/SummaryInfosDisplay';
import { DoughnutGraph } from 'components/MissionFollowUp/Charts/Doughnut';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { ActivitiesActivityBillingTypeChoices } from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { POLY_DATE } from 'poly-constants';
import React from 'react';
import { useSelector } from 'store';
import { getFormattedBills } from 'utils';

import { getFormatStringCollaboratorADR, getFormatStringTask } from '../utils';
import styles from './styles/ListBills.module.scss';

export default function PurchaseOrderSummary() {
  const SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED =
    !!useEnvironmentVariable('send_bill_email');
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );
  const currentMission = useSelector((state) => state.activity.currentMission);

  let sumPoSubMissionCollab = 0;
  if (currentPurchaseOrder.poSubMissions) {
    sumPoSubMissionCollab += currentPurchaseOrder.poSubMissions.length;
    sumPoSubMissionCollab += _.sumBy(
      currentPurchaseOrder.poSubMissions,
      (po) => po.collaborators?.length || 0
    );
  }
  return (
    <ExpandableCard
      className={styles.paperContainer}
      collapsedContentHeight={200}
      collapseEnabled={
        (currentPurchaseOrder?.collaborators?.length ||
          currentPurchaseOrder?.tasks?.length ||
          sumPoSubMissionCollab ||
          0) > 3
      }
    >
      <div className={styles.container}>
        <Grid container>
          <Grid item xs={7}>
            <SummaryInfosDisplay
              label={'Numéro de bon de commande'}
              content={currentPurchaseOrder.number}
            />
            <Grid item container xs={12}>
              {!!currentPurchaseOrder.total && (
                <Grid item xs={4}>
                  <SummaryInfosDisplay
                    label={
                      currentMission.billingType !==
                      ActivitiesActivityBillingTypeChoices.Tm
                        ? 'Montant total (HT)'
                        : 'Montant maximal régie (optionnel)'
                    }
                    content={totalFormat(
                      currentPurchaseOrder.total,
                      currentPurchaseOrder.currency
                    )}
                  />
                </Grid>
              )}
              <Grid item>
                <SummaryInfosDisplay
                  label={'Période'}
                  content={`${moment(
                    currentPurchaseOrder.periodBeginning
                  ).format(POLY_DATE)} - ${moment(
                    currentPurchaseOrder.periodEnding
                  ).format(POLY_DATE)}`}
                />
              </Grid>
            </Grid>
            <Grid item container>
              {currentMission.billingType !==
              ActivitiesActivityBillingTypeChoices.Tm ? (
                currentPurchaseOrder.tasks?.length !== 0 && (
                  <div>
                    <SummaryInfosDisplay
                      label={
                        <div className={styles.collaboratorLabel}>
                          Chantiers
                        </div>
                      }
                      content={
                        <div>
                          {_.map(currentPurchaseOrder.tasks, (task, index) => {
                            return (
                              <div
                                key={`task-${task.id}-${index}`}
                                className={
                                  index ? styles.collaboratorADRLabel : ''
                                }
                              >
                                {getFormatStringTask(
                                  task,
                                  currentPurchaseOrder.currency
                                )}
                              </div>
                            );
                          })}
                        </div>
                      }
                    />
                  </div>
                )
              ) : (
                <div>
                  <SummaryInfosDisplay
                    label={
                      <div className={styles.collaboratorLabel}>
                        Collaborateurs
                      </div>
                    }
                    content={
                      <div>
                        {_.map(
                          currentPurchaseOrder.collaborators,
                          (collaboratorADR, index) => {
                            return (
                              <div
                                key={`collaboratorADR-${collaboratorADR.id}`}
                                className={
                                  index ? styles.collaboratorADRLabel : ''
                                }
                              >
                                {getFormatStringCollaboratorADR(
                                  collaboratorADR,
                                  currentPurchaseOrder.currency
                                )}
                              </div>
                            );
                          }
                        )}
                        {_.map(
                          currentPurchaseOrder.poSubMissions,
                          (submission, index) => {
                            return (
                              <div
                                className={
                                  index ? styles.collaboratorADRLabel : ''
                                }
                                key={`po-submission-${submission.id}`}
                              >
                                <span>{submission.subMission.name}</span>
                                {_.map(submission.collaborators, (adr) => {
                                  return (
                                    <div
                                      className={styles.subMissionAdr}
                                      key={`po-collaborator-${currentPurchaseOrder.id}-${adr.id}`}
                                    >
                                      {getFormatStringCollaboratorADR(
                                        adr,
                                        currentPurchaseOrder.currency
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          }
                        )}
                      </div>
                    }
                  />
                </div>
              )}
            </Grid>
            <SummaryInfosDisplay
              label={'Adresse liée au bon de commande'}
              content={
                currentPurchaseOrder.billingAddress && (
                  <>
                    <div>
                      {`${currentPurchaseOrder.billingAddress.label}: ${currentPurchaseOrder.billingAddress.address}`}
                    </div>
                    <div>
                      {`${
                        currentPurchaseOrder.billingAddress.zipCode
                      }, ${currentPurchaseOrder.billingAddress.city?.toUpperCase()} - 
                      ${currentPurchaseOrder.billingAddress.country?.toUpperCase()}`}
                    </div>
                  </>
                )
              }
            />
          </Grid>
          <Grid item xs={5}>
            <div className={styles.detailSecondContainer}>
              {currentPurchaseOrder.bills &&
                currentPurchaseOrder.bills.length > 0 && (
                  <div className={styles.doughnut}>
                    <DoughnutGraph
                      data={getFormattedBills(
                        currentPurchaseOrder,
                        SEND_BILL_EMAIL_FEATURE_FLAG_ENABLED
                      )}
                      currency={currentPurchaseOrder.currency}
                    />
                  </div>
                )}
            </div>
          </Grid>
        </Grid>
      </div>
    </ExpandableCard>
  );
}
