import {
  AmountMonth,
  EstimatedClientForYear,
  EstimatedMissionForYear,
} from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/RevenueTableBody';
import {
  findActualAmountOrUndefined,
  findEstimatedAmountForMonth,
  getEstimatedMonthly,
  getMonthsWithActualRevenue,
  getTotalRevenueForRange,
} from 'components/Revenue/Estimated/utils';
import {
  ActivityNode,
  BillingClientNode,
  RevenueProspectiveNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { functionNotSet, getMissionLastActiveMonth } from 'utils';

export interface EstimatedContextProps {
  clients: EstimatedClientForYear[];
  missions: ActivityNode[];
  setMissions: (newMissions: ActivityNode[]) => void;
  currentYear: Moment;
  referenceDate: Moment;
  total: number;
  setTotalObjective: React.Dispatch<React.SetStateAction<number>>;
  totalObjective: number;
  revenueProspectives: RevenueProspectiveNode[];
  setRevenueProspectives: React.Dispatch<
    React.SetStateAction<RevenueProspectiveNode[]>
  >;
}

export interface EstimatedContextProviderProps {
  initialMissions: ActivityNode[];
  revenueProspectives: RevenueProspectiveNode[];
  setRevenueProspectives: React.Dispatch<
    React.SetStateAction<RevenueProspectiveNode[]>
  >;
  currentYear: Moment;
}

const EstimatedContext = createContext<EstimatedContextProps>({
  clients: [],
  missions: [],
  setMissions: () => functionNotSet(),
  currentYear: moment(),
  referenceDate: moment(),
  total: 0,
  setTotalObjective: () => functionNotSet(),
  totalObjective: 0,
  revenueProspectives: [],
  setRevenueProspectives: () => functionNotSet(),
});

export const useEstimatedContext = () => {
  const tableContext = useContext(EstimatedContext);
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

const getMonthsInYear = (year: Moment) => {
  const result = [];
  const start = year.clone().startOf('year');
  const end = start.clone().add(1, 'year');

  while (start.isBefore(end, 'month')) {
    result.push(start.clone());
    start.add(1, 'month');
  }
  return result;
};

function transformMission(
  mission: ActivityNode,
  refDate: Moment,
  months: Moment[],
  currentYear: Moment
): EstimatedMissionForYear {
  const objective = _.find(
    mission.revenueObjectives,
    (obj) => obj.year === currentYear.year()
  );
  let toBeEstimatedByMonth = 0;
  if (objective) {
    const actualTotal = getTotalRevenueForRange(
      months[0],
      months[months.length - 1],
      mission
    );
    const monthsWithActualRevenue = getMonthsWithActualRevenue(mission);
    const revenueEstimations = _.filter(
      mission.revenueEstimations,
      (estimation) =>
        moment(estimation.month).isSameOrAfter(refDate, 'month') &&
        !_.some(monthsWithActualRevenue, (month) =>
          moment(month).isSame(estimation.month)
        )
    );
    const totalEstimation = _.sumBy(revenueEstimations, 'amount');

    toBeEstimatedByMonth = getEstimatedMonthly(
      objective,
      actualTotal,
      refDate,
      mission,
      totalEstimation,
      revenueEstimations?.length || 0
    );
  }

  const actualTotals: AmountMonth[] = [];
  const estimatedTotals: AmountMonth[] = [];
  // compute totals for each month of mission that has a total
  _.forEach(months, (month) => {
    if (
      month.isBetween(
        moment(mission.startDate),
        moment(getMissionLastActiveMonth(mission)),
        'month',
        '[]'
      )
    ) {
      const actualAmount = findActualAmountOrUndefined(month, mission);
      if (actualAmount !== undefined) {
        actualTotals.push({ amount: actualAmount, month });
      } else if (month.isSameOrAfter(refDate, 'month')) {
        const estimatedAmount = findEstimatedAmountForMonth(month, mission);
        if (estimatedAmount) {
          estimatedTotals.push({ amount: estimatedAmount.amount, month });
        } else {
          estimatedTotals.push({
            amount: toBeEstimatedByMonth,
            month,
            hasComputedEstimation: true,
          });
        }
      }
    }
  });

  return {
    actualTotals,
    estimatedTotals,
    total:
      _.sumBy(actualTotals, 'amount') +
      _.sumBy(
        estimatedTotals,
        (actualTotal) => Math.round(actualTotal.amount * 1e2) / 1e2
      ),
    mission,
    objective: objective?.objective || 0,
  };
}

function computeClientMonthTotal(
  estimatedMissions: EstimatedMissionForYear[],
  refDate: Moment,
  months: Moment[]
): [AmountMonth[], AmountMonth[]] {
  const actualMissionsAmounts: AmountMonth[] = _.flatMap(
    estimatedMissions,
    'actualTotals'
  );
  const estimatedMissionsAmounts: AmountMonth[] = _.flatMap(
    estimatedMissions,
    'estimatedTotals'
  );

  const actualTotals: AmountMonth[] = [];
  const estimatedTotal: AmountMonth[] = [];
  _.forEach(months, (month) => {
    let actualAmount: number | undefined = undefined;

    const actualAmountMonths = _.filter(actualMissionsAmounts, (amountMonth) =>
      month.isSame(amountMonth.month)
    );
    if (!_.isEmpty(actualAmountMonths)) {
      actualAmount = _.sumBy(actualAmountMonths, 'amount');
      actualTotals.push({ amount: actualAmount, month });
    }

    if (month.isSameOrAfter(refDate, 'month')) {
      const estimatedAmountMonths = _.filter(
        estimatedMissionsAmounts,
        (amountMonth) => month.isSame(amountMonth.month)
      );
      if (!_.isEmpty(estimatedAmountMonths)) {
        const estimatedAmount = _.sumBy(estimatedAmountMonths, 'amount');
        const hasComputedEstimation = _.some(
          estimatedAmountMonths,
          'hasComputedEstimation'
        );
        estimatedTotal.push({
          amount: estimatedAmount,
          month,
          hasComputedEstimation,
        });
      }
    }
  });

  return [actualTotals, estimatedTotal];
}

const transformClient = (
  client: BillingClientNode,
  missions: ActivityNode[],
  refDate: Moment,
  months: Moment[],
  currentYear: Moment
): EstimatedClientForYear => {
  const estimatedMissions = _.map(missions, (m) =>
    transformMission(m, refDate, months, currentYear)
  );

  const [actualTotals, estimatedTotals] = computeClientMonthTotal(
    estimatedMissions,
    refDate,
    months
  );

  const prospectiveRevenueObjective = _.find(
    client.revenueProspectives,
    (revenueProspective) => {
      return revenueProspective.year === currentYear.year();
    }
  )?.objective;
  const objective =
    _.sumBy(estimatedMissions, 'objective') +
    (prospectiveRevenueObjective || 0);
  const total = _.sumBy(estimatedMissions, 'total');
  const startClient = _.minBy(estimatedMissions, (em) => em.mission.startDate);
  const endClient = _.maxBy(
    estimatedMissions,
    (em) => em.mission.expirationDate
  );
  return {
    client,
    dateRange: {
      start: moment(startClient?.mission.startDate),
      end: moment(endClient?.mission.expirationDate),
    },
    estimatedMissions,
    estimatedTotals,
    actualTotals,
    objective,
    total,
  } as EstimatedClientForYear;
};

const EstimatedContextProvider = ({
  initialMissions,
  revenueProspectives,
  setRevenueProspectives,
  currentYear,
  children,
}: PropsWithChildren<EstimatedContextProviderProps>) => {
  const referenceDate = moment();
  const [totalObjective, setTotalObjective] = useState(0);

  const [missions, setMissions] = useState(initialMissions);

  const clients = useMemo(() => {
    const missionsByClientDict = _.groupBy(
      missions,
      (m) => m.billingInformation?.billingClient?.id
    );
    const monthsInYear = getMonthsInYear(currentYear);
    const clients = _(missionsByClientDict)
      .flatMap((missions) => {
        const client = missions[0].billingInformation
          ?.billingClient as BillingClientNode;
        const transformedClient = transformClient(
          client,
          missions,
          referenceDate,
          monthsInYear,
          currentYear
        );
        return transformedClient;
      })
      .orderBy((client) => client.client.corporateName)
      .value();
    return clients;
  }, [currentYear, referenceDate, missions]);

  const total = useMemo(() => _.sumBy(clients, 'total'), [clients]);

  return (
    <EstimatedContext.Provider
      value={{
        clients,
        missions,
        currentYear: currentYear,
        referenceDate,
        total,
        totalObjective,
        setTotalObjective,
        setMissions,
        revenueProspectives,
        setRevenueProspectives,
      }}
    >
      {children}
    </EstimatedContext.Provider>
  );
};

export default EstimatedContextProvider;
