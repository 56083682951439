import { Grid } from '@mui/material';
import PolyAlert from 'components/commons/PolyAlert';
import PolyAlertTitle from 'components/commons/PolyAlertTitle';
import CreateOrUpdateFooter from 'components/MissionFollowUp/BillDetail/BillDetail/CreateOrUpdateFooter';
import ProvisionBillForm from 'components/MissionFollowUp/BillDetail/BillDetail/ProvisionBillForm';
import UpdateStatusFooter from 'components/MissionFollowUp/BillDetail/BillDetail/UpdateStatusFooter';
import GridItem from 'components/MissionFollowUp/GridItem';
import PolyFooter from 'components/MUIOverload/PolyFooter';
import {
  ActivitiesActivityBillingTypeChoices,
  AttachmentInput,
  BillingProvisionBillTypeChoices,
  BillNode,
  NumberOfDaysCollaboratorNode,
  useCreateOrUpdateBillMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { comaStringToFloat, totalFormat } from 'pages/ActivityPage/utils';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'store';
import {
  setBillForCurrentPurchaseOrder,
  setCurrentBill,
} from 'store/purchaseOrder';
import { graphQlDateFormatter } from 'utils';

import BillCollaboratorDays from './BillCollaboratorDays';
import BillDatePickers from './BillDatePickers';
import BilledAmount from './BilledAmount';
import BillErrors from './BillErrors';
import BillFiles from './BillFiles';
import BillInformation from './BillInformation';

export enum BillFormMode {
  CREATE,
  EDIT,
  VIEW,
  DUPLICATE,
}

interface BillFormProps {
  validCallback?: () => void;
  cancelCallback?: () => void;
  mode: BillFormMode;
}

export interface SubMissionType {
  collaborators: NumberOfDaysCollaboratorNode[];
}

export default function BillForm({
  validCallback,
  cancelCallback,
  mode = BillFormMode.CREATE,
}: BillFormProps): ReactElement {
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isCloning = mode === BillFormMode.DUPLICATE;
  const isEditing = mode === BillFormMode.EDIT;
  const isViewing = mode === BillFormMode.VIEW;
  const isCreating = mode === BillFormMode.CREATE;
  const { billingType } = useSelector((state) => state.activity.currentMission);
  const isTmContracts = billingType === ActivitiesActivityBillingTypeChoices.Tm;
  const currentBill = useSelector((state) => state.purchaseOrder.currentBill);
  const currentPurchaseOrder = useSelector(
    (state) => state.purchaseOrder.currentPurchaseOrder
  );

  const getBillMonthRange = useCallback(() => {
    const startPo = moment(currentPurchaseOrder.periodBeginning);
    const endPo = moment(currentPurchaseOrder.periodEnding);
    let startMonth = moment().subtract(1, 'months').startOf('month');
    if (!startMonth.isBetween(startPo, endPo, 'month', '[]')) {
      const closestMonth =
        startMonth.diff(startPo, 'month') - startMonth.diff(endPo, 'month');
      startMonth =
        closestMonth > 0 ? startPo.startOf('month') : endPo.startOf('month');
    }
    return {
      from: isCreating ? startMonth : moment(currentBill.periodBeginning),
      to: isCreating ? startMonth : moment(currentBill.periodEnding),
    };
  }, [
    currentBill.periodBeginning,
    currentBill.periodEnding,
    currentPurchaseOrder.periodBeginning,
    currentPurchaseOrder.periodEnding,
    isCreating,
  ]);

  const formDefaultValues = useMemo(() => {
    const cloneTitle = `${isCloning ? 'CLONE - ' : ''}${currentBill.title}`;
    const defaultProvisionBills = isTmContracts
      ? []
      : [
          {
            name: 'Prestation réalisée',
            amount: '',
            type: BillingProvisionBillTypeChoices.Standard,
          },
        ];

    function getSubMissions(): SubMissionType[] {
      return _.map(
        currentPurchaseOrder.poSubMissions,
        (poSubMission): SubMissionType => {
          return {
            collaborators: _.filter(
              currentBill.collaboratorsDays,
              (collaboratorDay) => {
                const collaboratorSubMissionId =
                  collaboratorDay.collaboratorRate.poSubMissions?.[0]
                    ?.subMission?.id;
                return collaboratorSubMissionId === poSubMission.subMission.id;
              }
            ),
          };
        }
      );
    }

    return {
      title: isCreating ? '' : cloneTitle,
      date: isCreating ? new Date() : currentBill.date,
      billMonthRange: getBillMonthRange(),
      collaborators: isCreating ? [] : currentBill.collaboratorsDays,
      subMissions: isCreating ? [] : getSubMissions(),
      provisionBills: isCreating
        ? defaultProvisionBills
        : _.map(currentBill.provisionBills, ({ name, amount, type }) => ({
            name,
            type,
            amount: totalFormat(amount, currentPurchaseOrder.currency),
          })),
      total: isCreating ? '' : totalFormat(currentBill.total),
      attachments: isCreating
        ? []
        : _.map(currentBill.attachments, (attachment) => {
            return {
              id: attachment.id,
              key: attachment.id,
              filename: attachment.filename,
              file: undefined,
            };
          }),
      // this property is used put the form in error
      smAdrs: true,
    };
  }, [
    currentBill.attachments,
    currentBill.collaboratorsDays,
    currentBill.date,
    currentBill.provisionBills,
    currentBill.title,
    currentBill.total,
    currentPurchaseOrder.currency,
    currentPurchaseOrder.poSubMissions,
    getBillMonthRange,
    isCloning,
    isCreating,
    isTmContracts,
  ]);

  const form = useForm({
    mode: 'onChange',
    defaultValues: formDefaultValues,
    criteriaMode: 'firstError',
    shouldUnregister: false,
  });

  const { control, handleSubmit, reset, watch } = form;

  const collaboratorsFieldArray = useFieldArray({
    control,
    name: 'collaborators',
    keyName: 'key',
  });

  useEffect(() => {
    reset(formDefaultValues);
  }, [formDefaultValues, reset]);

  const collaborators = watch('collaborators');
  const subMissions = watch('subMissions');
  const provisionBills = watch('provisionBills');
  const provisionBillSum = _.sumBy(provisionBills, ({ amount }) =>
    comaStringToFloat(amount)
  );
  const purchaseOrderBillsSum = _.sumBy(currentPurchaseOrder.bills, (bill) =>
    bill.id !== currentBill.id || isCloning || isCreating ? bill.total : 0
  );

  const purchaseOrderRemainingAmount = currentPurchaseOrder.total
    ? Number((currentPurchaseOrder.total - purchaseOrderBillsSum).toFixed(2))
    : Infinity;

  function sumCollaborators(collaborators: NumberOfDaysCollaboratorNode[]) {
    return _.map(collaborators, (collaborator) => {
      const dailyRate = collaborator?.collaboratorRate?.dailyRate || 0;
      return (
        _.sumBy(collaborator.daysNumbers, 'timeForTmContracts') * dailyRate
      );
    });
  }

  const totalInputs = () => {
    if (!_.isEmpty(subMissions)) {
      const sumByCollab = _.map(
        subMissions as SubMissionType[],
        (subMission) => {
          if (
            subMission &&
            subMission.collaborators &&
            subMission.collaborators.length !== 0
          ) {
            const sumArray = sumCollaborators(subMission.collaborators);
            return _.reduce(
              sumArray,
              (val, accumulator) => val + accumulator,
              0
            );
          }
          return 0;
        }
      );
      return _.sum(sumByCollab);
    } else {
      const sumByCollab = sumCollaborators(
        watch('collaborators')?.filter(Boolean) || []
      );
      return _.sum(sumByCollab);
    }
  };

  const getTotal = (): number => {
    const total =
      (provisionBillSum + (isTmContracts ? totalInputs() : 0)) *
      (currentBill.billReference ? -1 : 1);
    return Math.round(total * 100) / 100;
  };

  const [createOrUpdateBill, { loading, error }] =
    useCreateOrUpdateBillMutation({
      onError: () => {
        enqueueSnackbar(error?.message, {
          variant: 'error',
        });
      },
      onCompleted: (data) => {
        const bill = data.createOrUpdateBill?.bill as BillNode;
        if (bill) {
          dispatch(setBillForCurrentPurchaseOrder(bill));
        }
        if (bill && isEditing) {
          dispatch(setCurrentBill(bill));
        }
        if (validCallback) {
          validCallback();
        }
        enqueueSnackbar(
          isEditing
            ? `La facture « ${bill.title} » a été mise à jour`
            : `La facture « ${bill.title} » a bien été créée`,
          {
            variant: 'success',
          }
        );
      },
    });

  const onCancel = () => {
    if (cancelCallback) {
      cancelCallback();
      reset();
    }
  };

  const onSubmit = async (submitValues: typeof formDefaultValues) => {
    if (loading) {
      return;
    }
    const attachments: AttachmentInput[] = _.flatMap(
      submitValues.attachments,
      (x) => {
        return {
          id: x.id,
          file: x.file,
        };
      }
    );

    await createOrUpdateBill({
      variables: {
        purchaseOrderId: currentPurchaseOrder.id,
        billId: isCloning || isCreating ? undefined : currentBill.id,
        title: submitValues.title,
        total: getTotal(),
        date: submitValues.date
          ? graphQlDateFormatter(submitValues.date)
          : undefined,
        periodBeginning: graphQlDateFormatter(submitValues.billMonthRange.from),
        periodEnding: graphQlDateFormatter(submitValues.billMonthRange.to),
        provisionBills: _.map(
          submitValues.provisionBills,
          ({ name, amount, type }) => ({
            name,
            amount: comaStringToFloat(amount),
            type,
          })
        ),
        attachments: attachments,
        listNumberOfDaysCollaborator: getNbDaysCollaborators(
          !_.isEmpty(subMissions)
            ? _.flatMap(
                subMissions as SubMissionType[],
                (subMission) => subMission?.collaborators || []
              )
            : collaborators
        ),
      },
    });
    if (isCloning) {
      const missionId = new URLSearchParams(search).get('mission_id');
      const poId = new URLSearchParams(search).get('po_id');
      history.push({
        pathname: '/mission-followup/missions/details/purchase_order',
        search: `?mission_id=${missionId}&po_id=${poId}`,
      });
    }
  };

  let alertFormModeSubString = '';
  switch (mode) {
    case BillFormMode.EDIT: {
      alertFormModeSubString = "d'éditer";
      break;
    }
    case BillFormMode.DUPLICATE: {
      alertFormModeSubString = 'de dupliquer';
      break;
    }
    default: {
      alertFormModeSubString = 'de créer';
      break;
    }
  }

  return (
    <>
      {getTotal() > purchaseOrderRemainingAmount && (
        <PolyAlert variant="outlined" severity="error" sx={{ mb: 2 }}>
          <PolyAlertTitle color="error">
            Impossible {alertFormModeSubString} cette facture !
          </PolyAlertTitle>
          La somme des factures dépassent le montant du bon de commande. Il ne
          reste que{' '}
          <b>
            {totalFormat(
              purchaseOrderRemainingAmount,
              currentPurchaseOrder.currency
            )}
          </b>{' '}
          à facturer.
        </PolyAlert>
      )}

      <FormProvider {...form}>
        <form onSubmit={handleSubmit((d) => onSubmit(d))}>
          <BillInformation
            isCreating={isCreating}
            isEditing={isEditing}
            isViewing={isViewing}
          />

          <BillDatePickers isEditing={isEditing} isViewing={isViewing} />

          {isTmContracts && (
            <BillCollaboratorDays
              collaboratorsFieldArray={collaboratorsFieldArray}
              isCreating={isCreating}
              isEditing={isEditing}
              isViewing={isViewing}
            />
          )}

          <ProvisionBillForm disabled={!isEditing && isViewing} />

          <BilledAmount total={getTotal()} />

          {(!isViewing || !!currentBill?.attachments?.length) && (
            <BillFiles isEditing={isEditing} isViewing={isViewing} />
          )}

          {currentBill.cancellationCommentary && !isCloning && (
            <Grid container>
              <GridItem
                title={
                  "Commentaire justifiant la demande d'annulation/suppression"
                }
                customspacingtop={20}
                sizegrid={12}
              >
                {currentBill.cancellationCommentary.message}
              </GridItem>
            </Grid>
          )}

          <BillErrors subMissions={subMissions} />

          {(isCreating || isCloning || isEditing) && (
            <CreateOrUpdateFooter
              disabled={loading || getTotal() > purchaseOrderRemainingAmount}
              onCancel={onCancel}
              isCreating={isCreating}
            />
          )}
        </form>
      </FormProvider>
      {isViewing && (
        <PolyFooter>
          <UpdateStatusFooter />
        </PolyFooter>
      )}
    </>
  );
}

function getNbDaysCollaborators(
  collaborators: NumberOfDaysCollaboratorNode[] | undefined
) {
  return (
    _.map(collaborators, (collaborator) => {
      return {
        collaboratorRateId: collaborator.collaboratorRate?.id,
        daysNumbers: _.map(collaborator?.daysNumbers, 'id'),
      };
    }) || []
  );
}
