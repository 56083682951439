import { InputAdornment, TableCell } from '@mui/material';
import clsx from 'clsx';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import CommentButton from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/CommentModal/CommentButton';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import RevenueCellDisplayValue, {
  TypeCell,
} from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellDisplayValue';
import RevenueCellInput from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellInput';
import RevenueCellOutOfRange from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellOutOfRange';
import styles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/CollapseStyles.module.scss';
import stylesTable from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/TableCell.module.scss';
import {
  checkExceptionalMonth,
  isInRange,
  isInValidatedRangeMonths,
  isMonthValidated,
} from 'components/Revenue/Tables/util';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  BillingPurchaseOrderCurrencyChoices,
  PurchaseOrderNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment/moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { isNumber } from 'pages/MissionFollowUp/formValidators';
import { POLY_DATE_MONTH } from 'poly-constants';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TransitionStatus } from 'react-transition-group/Transition';

import { isMonthEditableForActualRevenueTable } from '../utils';

interface CompensationRowProps {
  purchaseOrder: PurchaseOrderNode;
  status: TransitionStatus;
}

export default function CompensationRow({
  purchaseOrder,
  status,
}: CompensationRowProps) {
  const {
    activity: { billingType },
    displayedMonths,
    exceptionalMonth,
    rangeValidatedMonth,
    isMissionEditable,
  } = useTableContext();
  const [poFormName] = useState(`revenuesByPo.${purchaseOrder.id}`);
  const [keyCompensation] = useState(`${poFormName}.compensations.`);
  const { isControl, isSuperuser } = useUserInfo();
  const { getValues, watch } = useFormContext();

  function getCompensationForMonth(month: moment.Moment) {
    const compensation = _.find(purchaseOrder.compensations, (compensation) => {
      return moment(compensation.month).isSame(month, 'month');
    });
    return compensation;
  }

  function getCell(month: moment.Moment) {
    const po = purchaseOrder;
    const formName = `${keyCompensation}${month.format(POLY_DATE_MONTH)}`;
    const formAmount = `${formName}.amount`;
    const validated = getValues(`${formName}.validated`);

    const isExceptionalMonth = checkExceptionalMonth(month, exceptionalMonth);

    const emptyCell = month.isBefore(moment())
      ? totalFormat(0, BillingPurchaseOrderCurrencyChoices.Eur)
      : '_';
    const compensation = getCompensationForMonth(month);
    const compensationValue = compensation
      ? totalFormat(
          compensation.amount,
          BillingPurchaseOrderCurrencyChoices.Eur
        )
      : emptyCell;
    const CommentAdornment = () => {
      const watchedCompensation = watch(formName);

      if (!watchedCompensation?.amount || !getValues(`${formName}.comment`)) {
        return undefined;
      }

      if (moment(compensation?.month).isSame(moment(), 'month')) {
        return (
          <InputAdornment position="end">
            <CommentButton
              isValidated={validated}
              comment={compensation?.comment || ''}
              sx={{ p: '10px 3px' }}
              currentPo={purchaseOrder}
              currentMonth={month}
              formName={formName}
            />
          </InputAdornment>
        );
      }

      return (
        <CommentButton
          isValidated={validated}
          comment={compensation?.comment || ''}
          currentPo={purchaseOrder}
          currentMonth={month}
          formName={formName}
        />
      );
    };
    const periodEnding =
      billingType === ActivitiesActivityBillingTypeChoices.Tm
        ? moment(po.periodEnding).add(1, 'months')
        : po.periodEnding;
    if (!isInRange(po.periodBeginning, periodEnding, month)) {
      const isValidated = isInValidatedRangeMonths(rangeValidatedMonth, month);
      return (
        <RevenueCellOutOfRange
          key={formAmount}
          isValidated={isValidated}
          isCollapsable={!isValidated}
        />
      );
    }

    const isMonthEditable = isExceptionalMonth || !isMonthValidated(month, po);
    const isCellEditable =
      (isControl ||
        isSuperuser ||
        isMonthEditableForActualRevenueTable(month)) &&
      isMissionEditable &&
      isMonthEditable;

    return isCellEditable ? (
      <RevenueCellInput
        key={formAmount}
        isCollapsable
        disabled={exceptionalMonth && !isExceptionalMonth}
        formName={formAmount}
        validateRules={{
          validate: { isNumber },
        }}
        customAdornment={CommentAdornment()}
      />
    ) : (
      <RevenueCellDisplayValue
        value={compensationValue}
        isCollapsable
        key={formAmount}
        customAdornment={CommentAdornment()}
        typeOfCell={TypeCell.italic}
      />
    );
  }

  return (
    <ChildCollapsePolyTableRow status={status}>
      <TableCell className={clsx(styles.nameCell, stylesTable.italic)}>
        Compensation
      </TableCell>
      <TableCell className={styles.totalCell} />
      {_.map(displayedMonths, (month) => getCell(month))}
    </ChildCollapsePolyTableRow>
  );
}
