import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // ES 2015
import minMax from 'dayjs/plugin/minMax'; // ES 2015
import { ActivityNode } from 'generated/graphql';
import React from 'react';

import ActivityAdrGraph from './ActivityAdrGraph';
import ActivityRevenueGraph from './ActivityRevenueGraph';

const FinancialReport = ({ activity }: { activity: ActivityNode }) => {
  const firstDayOfMonth = (date = new Date()) =>
    new Date(date.getFullYear(), date.getMonth(), 1);

  const maxEndDate = dayjs().subtract(1, 'month').endOf('month').toDate();
  const [startDate, setStartDate] = React.useState<Date | null>(
    dayjs(activity.startDate).toDate()
  );
  const [endDate, setEndDate] = React.useState<Date | null>(maxEndDate);

  dayjs.extend(minMax);
  dayjs.extend(isSameOrAfter);

  const handleStartDateChange = (newStartDate: Date | null) => {
    if (newStartDate && endDate && newStartDate > endDate) {
      // Ensure startDate is not after endDate
      setEndDate(
        dayjs
          .min(
            dayjs(activity.expirationDate),
            dayjs(newStartDate).endOf('year'),
            dayjs().subtract(1, 'month').endOf('month') // TODO after handling the future: remove after implemanting future
          )
          .toDate()
      );
    }
    if (newStartDate && dayjs(newStartDate).isSameOrAfter(maxEndDate)) {
      // Ensure startDate is not after maxEndDate
      setStartDate(firstDayOfMonth(maxEndDate));
    } else {
      setStartDate(newStartDate);
    }
  };

  const handleEndDateChange = (newEndDate: Date | null) => {
    if (newEndDate && startDate && newEndDate < startDate) {
      // Ensure endDate is not before startDate
      setStartDate(
        dayjs
          .max(dayjs(activity.startDate), dayjs(newEndDate).startOf('year'))
          .toDate()
      );
    }
    if (newEndDate && dayjs(newEndDate).isAfter(maxEndDate)) {
      // Ensure endDate is not after maxEndDate
      setEndDate(maxEndDate);
    } else {
      setEndDate(newEndDate);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={3} my={3}>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          position: 'sticky',
          top: 10,
          zIndex: 1000,
        }}
      >
        <DatePicker
          views={['month', 'year']}
          openTo="month"
          label="à partir de"
          minDate={dayjs(activity.startDate).startOf('month').toDate()}
          maxDate={maxEndDate} // TODO after handling the future : dayjs(activity.expirationDate).endOf('month').toDate()
          value={startDate}
          onChange={(newStartDate) => {
            handleStartDateChange(newStartDate);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, readOnly: true }}
              sx={{ backgroundColor: 'white', borderRadius: 1 }}
            />
          )}
        />
        <DatePicker
          views={['month', 'year']}
          openTo="month"
          label="jusqu'à"
          minDate={dayjs(activity.startDate).startOf('month').toDate()}
          maxDate={maxEndDate} // TODO after handling the future : dayjs(activity.expirationDate).endOf('month').toDate()
          value={endDate}
          onChange={(newEndDate) => {
            handleEndDateChange(newEndDate);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, readOnly: true }}
              sx={{ backgroundColor: 'white', borderRadius: 1 }}
            />
          )}
        />
      </Stack>
      {startDate && endDate && (
        <>
          <ActivityRevenueGraph
            activityId={activity.id}
            startDate={startDate}
            endDate={endDate}
          />
          <ActivityAdrGraph
            activityId={activity.id}
            startDate={startDate}
            endDate={endDate}
          />
        </>
      )}
    </Box>
  );
};

export default FinancialReport;
