import MessageIcon from '@mui/icons-material/Message';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { IconButton, IconButtonProps } from '@mui/material';
import CommentModal from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/CommentModal/CommentModal';
import styles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/TableCell.module.scss';
import { PurchaseOrderNode } from 'generated/graphql';
import { Moment } from 'moment';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface CommentaryModalProps {
  currentPo: PurchaseOrderNode;
  currentMonth: Moment;
  closeModal?: () => void;
  formName: string;
  compensationExists?: boolean;
  comment: string;
  isValidated: boolean;
}

const CommentButton = (properties: CommentaryModalProps & IconButtonProps) => {
  const [open, setOpen] = useState(false);
  const form = useFormContext();
  const commentProperties: CommentaryModalProps = { ...properties };
  const buttonProperties: IconButtonProps = { ...properties };
  return (
    <React.Fragment>
      <CommentModal
        open={open}
        currentPo={commentProperties.currentPo}
        month={commentProperties.currentMonth}
        closeModal={() => setOpen(false)}
        comment={commentProperties.comment}
        formName={commentProperties.formName}
      />
      <IconButton
        {...buttonProperties}
        className={styles.cellsIcon}
        onClick={() => setOpen(true)}
        size="large"
      >
        {form.watch(`${properties.formName}.comment`) ? (
          <MessageIcon fontSize={'small'} />
        ) : (
          <MessageOutlinedIcon fontSize={'small'} />
        )}
      </IconButton>
    </React.Fragment>
  );
};

export default CommentButton;
