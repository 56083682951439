import { Box } from '@mui/material';
import { FlatTable } from 'components/commons/Tables/FlatTable';
import TableHeaderContextProvider from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import PongoButton from 'components/MUIOverload/PongoButton';
import TableContextProvider from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueMissionTable/context/TableContextProvider';
import RevenueTableBody from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueMissionTable/RevenueTableBody';
import RevenueTableHeader from 'components/Revenue/Estimated/EstimatedRevenueTables/RevenueTableHeader';
import {
  getMonthsWithActualRevenue,
  getRefDate,
  verifyEmployeePermissions,
} from 'components/Revenue/Estimated/utils';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivityNode,
  AmountInput,
  useSubmitEstimationsForActivityMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { POLY_DATE_MONTH } from 'poly-constants';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { graphQlDateFormatter } from 'utils';

interface RevenueGlobalTableProps {
  activity: ActivityNode;
  currentYear: Moment;
  setCurrentYear: (p: Moment) => void;
}

const EstimatedRevenueMissionTable = ({
  activity,
  currentYear,
  setCurrentYear,
}: RevenueGlobalTableProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { employee } = useUserInfo();

  const monthsWithActualRevenue = getMonthsWithActualRevenue(activity);
  const estimatedRevenues = useMemo(() => {
    const refDate = getRefDate(currentYear);
    return _.filter(
      activity.revenueEstimations,
      (estimation) =>
        moment(estimation.month).isSameOrAfter(refDate, 'month') &&
        !_.some(monthsWithActualRevenue, (month) =>
          moment(month).isSame(estimation.month)
        )
    );
  }, [activity.revenueEstimations, currentYear, monthsWithActualRevenue]);

  const defaultValues = _.mapValues(
    _.keyBy(estimatedRevenues, (estimation) =>
      moment(estimation.month).format(POLY_DATE_MONTH)
    ),
    'amount'
  );

  const form = useForm({
    defaultValues: defaultValues,
    shouldUnregister: false,
  });

  const [submitEstimation] = useSubmitEstimationsForActivityMutation({
    onCompleted: () => {
      enqueueSnackbar('Données enregistrées', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const onSubmit = async (submitValues: Record<string, number>) => {
    const estimationInputs: AmountInput[] = _.map(
      submitValues,
      (amount, monthStr) => {
        return {
          amount: _.isNumber(amount) ? amount : undefined,
          month: graphQlDateFormatter(moment(monthStr, POLY_DATE_MONTH)),
        };
      }
    );
    await submitEstimation({
      variables: {
        activityId: activity.id,
        estimations: estimationInputs,
      },
    });
  };

  const { isDirector, isChief } = verifyEmployeePermissions(activity, employee);
  const hasRightToEdit = isChief || isDirector;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit((d) => onSubmit(d))}>
        <TableContextProvider
          activity={activity}
          hasRightToEdit={hasRightToEdit}
        >
          <TableHeaderContextProvider
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          >
            <FlatTable size={'small'} sx={{ tableLayout: 'auto' }}>
              <RevenueTableHeader />
              <RevenueTableBody />
            </FlatTable>
            <Box display={'flex'} justifyContent={'flex-end'} mt={3}>
              <PongoButton
                color={'primary'}
                variant={'contained'}
                type={'submit'}
                disabled={!form.formState.isDirty || !_.isEmpty(form.errors)}
              >
                Enregistrer
              </PongoButton>
            </Box>
          </TableHeaderContextProvider>
        </TableContextProvider>
      </form>
    </FormProvider>
  );
};

export default EstimatedRevenueMissionTable;
