import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton } from '@mui/material';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import ValidationModal from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/ValidationButton/ValidationModal';
import { Moment } from 'moment';
import React, { useState } from 'react';

import styles from './styles/CompensationModal.module.scss';

interface ValidationButtonProps {
  month: Moment;
}
export const ValidationButton = ({ month }: ValidationButtonProps) => {
  const { setExceptionalMonth, resetValues } = useTableContext();
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.compensationContainer}>
      <ValidationModal
        open={open}
        month={month}
        closeModal={() => setOpen(false)}
        onSuccess={() => {
          setExceptionalMonth();
          resetValues();
        }}
      />
      <IconButton
        onClick={() => setOpen(true)}
        className={styles.checkIconButton}
        title={'Valider ce mois'}
        size="large"
      >
        <CheckCircleIcon
          color="primary"
          sx={{ height: '30px', width: '30px' }}
        />
      </IconButton>
    </div>
  );
};
