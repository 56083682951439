import { ActivityNode, RevenueProspectiveNode } from 'generated/graphql';
import moment, { Moment } from 'moment';
import React, { createContext, PropsWithChildren, useContext } from 'react';

export interface DisplayedRevenueProspective {
  id?: string;
  name: string;
  objective: number;
}

interface TableContextProviderProps {
  children?: React.ReactNode;
  missions: ActivityNode[];
  revenueProspectives: RevenueProspectiveNode[];
  referenceDate: Moment;
}

const TableContext = createContext<TableContextProviderProps>({
  missions: [],
  referenceDate: moment(),
  revenueProspectives: [],
});

export const useTableContext = () => {
  const tableContext = useContext(TableContext);
  if (!tableContext) {
    throw new Error('useContext() can only be used  inside a table');
  }
  return tableContext;
};

const TableContextProvider = (
  properties: PropsWithChildren<TableContextProviderProps>
) => {
  return (
    <TableContext.Provider
      value={{
        revenueProspectives: properties.revenueProspectives,
        missions: properties.missions,
        referenceDate: properties.referenceDate,
      }}
    >
      {properties.children}
    </TableContext.Provider>
  );
};

export default TableContextProvider;
