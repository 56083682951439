import { TableBody, TableRow, Typography } from '@mui/material';
import {
  BorderedTableCell,
  CenteredTableCell,
} from 'components/commons/Tables/FlatTable';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import TextFieldNumberFormat from 'components/commons/TextFieldFormat/TextFieldNumberFormat';
import PolyGridInputForm from 'components/MissionFollowUp/PolyGridInputForm';
import { handleSuggestionOnEnter } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellInput';
import { useTableContext } from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueMissionTable/context/TableContextProvider';
import styles from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueMissionTable/styles/TableCell.module.scss';
import {
  actualRevenueBackgroundColorSx,
  estimatedRevenueBackgroundColorSx,
  findActualAmountOrUndefined,
  getEstimatedMonthly,
  getRefDate,
  getTotalRevenueForRange,
} from 'components/Revenue/Estimated/utils';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { POLY_DATE_MONTH } from 'poly-constants';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { getMissionLastActiveMonth } from 'utils';

const RevenueTableBody = () => {
  const { activity, hasRightToEdit } = useTableContext();
  const { displayedMonths, currentYear } = useTableHeaderContext();

  const objective = _.find(activity.revenueObjectives, (objective) =>
    currentYear.isSame(moment(objective.year, 'YYYY'), 'year')
  );

  const referenceDate = getRefDate(currentYear);

  const { watch } = useFormContext();

  const actualTotal = getTotalRevenueForRange(
    currentYear.clone().startOf('year'),
    currentYear.clone().endOf('year'),
    activity
  );

  const getTotal = useCallback(() => {
    const revenues = _.sumBy(
      _.values(watch()),
      (value) => (value || 0) as number
    );
    return (actualTotal || 0) + (revenues || 0);
  }, [actualTotal, watch]);

  const toBeEstimated = useMemo(() => {
    if (!objective) {
      return 0;
    }
    const filledValues = _.filter(
      _.values(watch()),
      (value) => value !== '' && value !== undefined
    );

    const estimationTotal =
      _.sumBy(_.values(watch()), (value) => (value || 0) as number) || 0;

    return getEstimatedMonthly(
      objective,
      actualTotal,
      referenceDate,
      activity,
      estimationTotal,
      filledValues.length
    );
  }, [activity, actualTotal, watch, objective, referenceDate]);

  const getTableCellContent = (month: Moment, actualAmount?: number) => {
    if (actualAmount !== undefined || month.isBefore(referenceDate, 'month')) {
      return (
        <Typography
          sx={{
            textAlign: 'center',
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
          }}
        >
          {totalFormat(
            actualAmount || 0,
            BillingPurchaseOrderCurrencyChoices.Eur
          )}
        </Typography>
      );
    }
    if (!hasRightToEdit) {
      const estimatedAmount = watch(month.format(POLY_DATE_MONTH));
      if (estimatedAmount === undefined) {
        return (
          <Typography
            sx={{ color: 'gray', textAlign: 'center', fontSize: '0.875rem' }}
          >
            {totalFormat(
              toBeEstimated,
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          </Typography>
        );
      }
      return (
        <Typography
          sx={{
            textAlign: 'center',
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
          }}
        >
          {totalFormat(
            estimatedAmount,
            BillingPurchaseOrderCurrencyChoices.Eur
          )}
        </Typography>
      );
    }
    return (
      <PolyGridInputForm
        hiddenLabel
        fullWidth
        variant="filled"
        alignment="center"
        formName={month.format(POLY_DATE_MONTH)}
        defaultValue={''}
        placeholder={
          totalFormat(toBeEstimated, BillingPurchaseOrderCurrencyChoices.Eur) ||
          ''
        }
        onKeyPress={handleSuggestionOnEnter}
        InputProps={{
          inputComponent: TextFieldNumberFormat as never,
        }}
        inputProps={{
          sx: { p: 0.5 },
          suggestedValue: toBeEstimated.toFixed(2) || '',
          currency: BillingPurchaseOrderCurrencyChoices.Eur,
        }}
        validateRules={{}}
      />
    );
  };

  return (
    <TableBody className={styles.tableBody}>
      <TableRow sx={{ height: '48px' }}>
        <BorderedTableCell>
          <Typography fontSize={'0.875rem'}>{activity.name}</Typography>
        </BorderedTableCell>
        {_.map(displayedMonths, (month) => {
          if (
            !month.isBetween(
              moment(activity.startDate),
              moment(getMissionLastActiveMonth(activity)),
              'month',
              '[]'
            )
          ) {
            return (
              <OutOfRangeTableCell
                key={`body-${month.format('MMM')}`}
                title={"La mission n'est pas active sur ce mois"}
              />
            );
          }
          const actualRevenue = findActualAmountOrUndefined(month, activity);
          const isActualRevenue =
            actualRevenue !== undefined ||
            month.isBefore(referenceDate, 'month');
          return (
            <BorderedTableCell
              sx={{
                ...(isActualRevenue
                  ? actualRevenueBackgroundColorSx
                  : estimatedRevenueBackgroundColorSx),
              }}
              key={`body-${month.format('MMM')}`}
            >
              {getTableCellContent(month, actualRevenue)}
            </BorderedTableCell>
          );
        })}
        <CenteredTableCell>
          <Typography
            fontWeight={'bold'}
            sx={{ whiteSpace: 'nowrap', fontSize: '0.875rem' }}
          >
            {totalFormat(getTotal(), BillingPurchaseOrderCurrencyChoices.Eur)}
          </Typography>
        </CenteredTableCell>
        <CenteredTableCell>
          <Typography
            fontWeight={'bold'}
            sx={{ whiteSpace: 'nowrap', fontSize: '0.875rem' }}
          >
            {objective
              ? totalFormat(
                  objective.objective,
                  BillingPurchaseOrderCurrencyChoices.Eur
                )
              : 'N/A'}
          </Typography>
        </CenteredTableCell>
      </TableRow>
    </TableBody>
  );
};

export default RevenueTableBody;
