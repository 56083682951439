import { Box } from '@mui/material';
import LoadingPlaceholder from 'components/commons/LoadingPlaceholder';
import PageWrapper from 'components/commons/PageWrapper';
import TabHeader from 'components/commons/TabHeader';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import { EstimatedObjectiveForm } from 'components/Revenue/Estimated/EstimatedObjectiveForm';
import { EstimatedRevenueSumUp } from 'components/Revenue/Estimated/EstimatedRevenueSumUp';
import EstimatedRevenueMissionTable from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueMissionTable';
import { EstimatedRevenueYear } from 'components/Revenue/Estimated/EstimatedRevenueYear';
import {
  ActivityNode,
  useFetchEstimatedRevenuesForMissionQuery,
  useSubmitObjectivesForYearMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { PageTitles } from 'poly-constants';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'store';

import EstimatedRevenueExportButton from './EstimatedRevenueExportButton';

export default function EstimatedRevenueMissionPage() {
  const { enqueueSnackbar } = useSnackbar();
  const missionId = useSelector((state) => state.activity.currentMission.id);
  const { currentYear, setCurrentYear } = useTableHeaderContext();
  const form = useForm({
    shouldUnregister: false,
  });
  const { loading, data } = useFetchEstimatedRevenuesForMissionQuery({
    variables: {
      activityId: missionId || '',
      year: currentYear.year(),
    },
  });

  const [submitObjective] = useSubmitObjectivesForYearMutation({
    onCompleted: () => {
      enqueueSnackbar('Objectif mis à jour', {
        variant: 'success',
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
  });

  const activity = data?.allEstimatedRevenuesForActivity as ActivityNode;
  const objective = _.find(activity?.revenueObjectives, (objectives) =>
    moment(objectives.year, 'YYYY').isSame(currentYear, 'year')
  );

  const updateObjective = async (submitValues: Record<string, number>) => {
    await submitObjective({
      variables: {
        year: currentYear.year(),
        revenueObjectives: [
          {
            activity: activity.id,
            objective: submitValues['objective'] || 0,
          },
        ],
      },
    });
  };

  return (
    <PageWrapper missionView>
      <TabHeader
        title={PageTitles.estimatedRevenuePage}
        yearSelectorComponent={<EstimatedRevenueYear />}
        actionComponents={<EstimatedRevenueExportButton year={currentYear} />}
      />
      {loading ? (
        <LoadingPlaceholder />
      ) : (
        <Box>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit((d) => updateObjective(d))}>
              <EstimatedObjectiveForm
                objective={objective?.objective}
                activity={activity}
              />
            </form>
          </FormProvider>
          <EstimatedRevenueSumUp
            activity={activity}
            objective={objective}
            currentYear={currentYear}
          />
          <Box sx={{ mt: 4 }} />
          <EstimatedRevenueMissionTable
            activity={activity}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
          />
        </Box>
      )}
    </PageWrapper>
  );
}
