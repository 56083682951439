import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import React from 'react';

import styles from './styles/CompensationModal.module.scss';

export const CancelEditValidationButton = () => {
  const { setExceptionalMonth, resetValues } = useTableContext();

  return (
    <div className={styles.editContainer}>
      <IconButton
        onClick={() => {
          setExceptionalMonth();
          resetValues();
        }}
        className={styles.checkIconButton}
        title={'Annuler'}
        size="large"
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
};
