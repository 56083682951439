import { Stack, Typography } from '@mui/material';
import AvatarInitial from 'components/commons/AvatarInitial/AvatarInitial';
import {
  BorderedTableCell,
  CenteredTableCell,
} from 'components/commons/Tables/FlatTable';
import { useTableHeaderContext } from 'components/commons/Tables/Header/Contexts/TableHeaderContextProvider';
import TextFieldNumberFormat from 'components/commons/TextFieldFormat/TextFieldNumberFormat';
import PolyGridInputForm from 'components/MissionFollowUp/PolyGridInputForm';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import PolyTooltip from 'components/MUIOverload/PolyTooltip';
import { handleSuggestionOnEnter } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/CellTypes/RevenueCellInput';
import { useTableContext } from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/context/TableContextProvider';
import { EstimatedMissionForYear } from 'components/Revenue/Estimated/EstimatedRevenueTables/EstimatedRevenueGlobalTable/RevenueTableBody';
import {
  actualRevenueBackgroundColorSx,
  estimatedRevenueBackgroundColorSx,
  verifyEmployeePermissions,
} from 'components/Revenue/Estimated/utils';
import OutOfRangeTableCell from 'components/Revenue/Tables/RevenueTableCells/OutOfRangeTableCell';
import { useUserInfo } from 'components/User/UserProvider';
import { BillingPurchaseOrderCurrencyChoices } from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { totalFormat } from 'pages/ActivityPage/utils';
import { isNumberNullOrPositive } from 'pages/MissionFollowUp/formValidators';
import { POLY_DATE_MONTH } from 'poly-constants';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { TransitionStatus } from 'react-transition-group/Transition';
import { displayEmployeeNode, getMissionLastActiveMonth } from 'utils';

interface MissionRowProps {
  estimatedMission: EstimatedMissionForYear;
  status: TransitionStatus;
}

const MissionRow = ({ estimatedMission, status }: MissionRowProps) => {
  const { referenceDate } = useTableContext();
  const { displayedMonths, currentYear } = useTableHeaderContext();
  const { employee } = useUserInfo();
  const history = useHistory();
  const { pathname } = useLocation();

  const { mission, actualTotals, estimatedTotals, total, objective } =
    estimatedMission;

  const missionPath = history.createHref({
    pathname: pathname,
    search: `?mission_id=${mission.id}&year=${currentYear.year()}`,
  });

  const { isDirector, isChief } = verifyEmployeePermissions(mission, employee);
  const hasRightToEdit = isChief || isDirector;
  const isActive = (month: Moment) => {
    return month.isBetween(
      moment(mission.startDate),
      moment(getMissionLastActiveMonth(mission)),
      'month',
      '[]'
    );
  };

  return (
    <ChildCollapsePolyTableRow status={status}>
      <BorderedTableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <PolyTooltip title={displayEmployeeNode(mission.director, false)}>
            <span>
              <AvatarInitial employee={mission.director} />
            </span>
          </PolyTooltip>
          <Link to={missionPath} rel={'noopener noreferrer'}>
            <Typography
              color={'text.secondary'}
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                },
                fontSize: '0.875rem',
              }}
            >
              {mission.name}
            </Typography>
          </Link>
        </Stack>
      </BorderedTableCell>
      {_.map(displayedMonths, (month) => {
        if (!isActive(month)) {
          return (
            <OutOfRangeTableCell
              key={`total-mission-${mission.id}-${month.format(
                POLY_DATE_MONTH
              )}`}
              title={"La mission n'est pas active sur ce mois"}
            />
          );
        }
        const actualRevenue = _.find(actualTotals, (actual) =>
          month.isSame(actual.month, 'month')
        );
        const isActualRevenue =
          actualRevenue !== undefined || month.isBefore(referenceDate, 'month');
        const estimatedRevenue = _.find(estimatedTotals, (estimated) =>
          month.isSame(estimated.month, 'month')
        );
        return (
          <CenteredTableCell
            sx={{
              ...(isActualRevenue
                ? actualRevenueBackgroundColorSx
                : estimatedRevenueBackgroundColorSx),
            }}
            key={`header-${month.format('MMM')}`}
          >
            <Typography
              fontSize="0.875rem"
              sx={
                estimatedRevenue?.hasComputedEstimation ? { color: 'gray' } : {}
              }
            >
              {totalFormat(
                (actualRevenue || estimatedRevenue)?.amount || 0,
                BillingPurchaseOrderCurrencyChoices.Eur
              )}
            </Typography>
          </CenteredTableCell>
        );
      })}
      <CenteredTableCell>
        <Typography fontWeight={'bold'} fontSize="0.875rem">
          {totalFormat(total, BillingPurchaseOrderCurrencyChoices.Eur)}
        </Typography>
      </CenteredTableCell>
      <CenteredTableCell>
        {hasRightToEdit ? (
          <PolyGridInputForm
            hiddenLabel
            variant="filled"
            alignment="center"
            formName={`revenueObjectives.${mission.id}`}
            defaultValue={objective || 0}
            onKeyPress={handleSuggestionOnEnter}
            InputProps={{
              inputComponent: TextFieldNumberFormat as never,
            }}
            validateRules={{
              validate: {
                isNumberPositive: isNumberNullOrPositive,
              },
            }}
          />
        ) : (
          <Typography fontWeight={'bold'} fontSize="0.875rem">
            {totalFormat(
              objective || 0,
              BillingPurchaseOrderCurrencyChoices.Eur
            )}
          </Typography>
        )}
      </CenteredTableCell>
    </ChildCollapsePolyTableRow>
  );
};

export default MissionRow;
