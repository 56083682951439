import { Stack, Typography } from '@mui/material';
import { GridColDef, GridToolbar, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import ActivityIndicator, {
  indicatorToColorMap,
} from 'components/commons/ActivityIndicator';
import MissionTypeIcon from 'components/commons/MissionTypeIcon';
import MuixTable from 'components/commons/MuixTable';
import {
  ActivityStatusEnum,
  getActivityStatus,
  getActivityStatusChip,
} from 'components/MissionPage/utils';
import { getUrlList } from 'components/Navigation/DesktopNavbar/utils';
import {
  ActivitiesActivityBillingTypeChoices,
  AllActivitiesForRevenueAnalysisQuery,
  DashboardRevenueAnalysisQuery,
  IndicatorChoices,
  useAllActivitiesForRevenueAnalysisQuery,
  useDashboardRevenueAnalysisQuery,
} from 'generated/graphql';
import { isEqual } from 'lodash';
import moment from 'moment';
import { EMPTY_FIELD, POLY_DATE } from 'poly-constants';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'store';
import { setAnalysisTable, setDashboardTable } from 'store/indicatorTables';
import urljoin from 'url-join';

import CommentTooltip from './CommentTooltip';
import Profit from './Profit';

const INDICATOR_VALUE_OPTIONS = Object.values(indicatorToColorMap).map(
  ({ text }) => text
);

const defaultColumns: GridColDef[] = [
  {
    field: 'activity.name',
    headerName: 'Mission',
    type: 'string',
    minWidth: 150,
    flex: 2,
    valueGetter: ({ row }) => row.activity.name,
    renderCell: ({ value, row: { activity } }) => (
      <Stack direction="row" gap={1} alignItems="center" minWidth={0}>
        <MissionTypeIcon
          type={activity.type}
          isTmContracts={
            activity.billingType === ActivitiesActivityBillingTypeChoices.Tm
          }
          tooltip
        />
        <Typography variant="bodyS" noWrap>
          {value}
        </Typography>
      </Stack>
    ),
  },
  {
    field: 'activity.businessClient',
    headerName: 'Client',
    type: 'string',
    minWidth: 150,
    flex: 1,
    valueGetter: ({ row }) => row.activity.businessClient?.name || EMPTY_FIELD,
  },
  {
    field: 'latestIndicator.clientSatisfaction',
    headerName: 'Statut Client',
    type: 'singleSelect',
    flex: 1,
    valueOptions: INDICATOR_VALUE_OPTIONS,
    valueGetter: ({ row: { latestIndicator } }) =>
      latestIndicator?.clientSatisfaction
        ? indicatorToColorMap[
            latestIndicator.clientSatisfaction as IndicatorChoices
          ].text
        : null,
    renderCell: ({ row: { latestIndicator } }) =>
      latestIndicator?.clientSatisfaction ? (
        <ActivityIndicator indicator={latestIndicator.clientSatisfaction} />
      ) : (
        EMPTY_FIELD
      ),
  },
  {
    field: 'latestIndicator.efficiency',
    headerName: 'Statut Équipe',
    type: 'singleSelect',
    flex: 1,
    valueOptions: INDICATOR_VALUE_OPTIONS,
    valueGetter: ({ row: { latestIndicator } }) =>
      latestIndicator?.efficiency
        ? indicatorToColorMap[latestIndicator.efficiency as IndicatorChoices]
            .text
        : null,
    renderCell: ({ row: { latestIndicator } }) =>
      latestIndicator?.efficiency ? (
        <ActivityIndicator indicator={latestIndicator.efficiency} />
      ) : (
        EMPTY_FIELD
      ),
  },
  {
    field: 'activityProfit.totalRevenue',
    headerName: 'CA Total',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    valueGetter: ({ row: { activityProfit } }) => activityProfit.totalRevenue,
    renderCell: ({ value }) => <Profit profit={value} />,
  },
  {
    field: 'activityProfit.margin',
    headerName: 'Marge',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    valueGetter: ({ row: { activityProfit } }) => activityProfit.margin,
    renderCell: ({ value }) => <Profit profit={value} highlightProfit />,
  },
  {
    field: 'latestIndicator.createdAt',
    headerName: 'Dernière modification',
    type: 'date',
    flex: 1,
    valueGetter: ({ row: { latestIndicator } }) =>
      latestIndicator?.createdAt
        ? new Date(latestIndicator.createdAt)
        : undefined,
    renderCell: ({ value }) =>
      value ? moment(value).format(POLY_DATE) : EMPTY_FIELD,
  },
  {
    field: 'latestIndicator.comment',
    type: 'actions',
    maxWidth: 80,
    valueGetter: ({ row: { latestIndicator } }) => latestIndicator?.comment,
    renderCell: ({ value }) =>
      value ? <CommentTooltip comment={value} /> : EMPTY_FIELD,
  },
];

const statusColumn: GridColDef = {
  field: 'activity.expirationDate',
  headerName: 'Statut',
  align: 'center',
  type: 'singleSelect',
  flex: 1,
  valueOptions: Object.values(ActivityStatusEnum),
  valueGetter: ({ row: { activity } }) => {
    return getActivityStatus(activity.startDate, activity.expirationDate);
  },
  renderCell: ({ value }) => getActivityStatusChip(value),
  sortComparator: (v1, v2) => {
    const order = Object.values(ActivityStatusEnum);
    return order.indexOf(v1) - order.indexOf(v2);
  },
};

type ActivityIndicatorTable = {
  queryHook:
    | typeof useAllActivitiesForRevenueAnalysisQuery
    | typeof useDashboardRevenueAnalysisQuery;
};

function isAllActivitiesQueryResult(
  data:
    | AllActivitiesForRevenueAnalysisQuery
    | DashboardRevenueAnalysisQuery = {}
): data is AllActivitiesForRevenueAnalysisQuery {
  return 'allActivitiesForRevenueAnalysis' in data;
}

export default function ActivityIndicatorTable({
  queryHook,
}: ActivityIndicatorTable): JSX.Element {
  const history = useHistory();
  const urlList = getUrlList();
  const { data: activities, loading } = queryHook();
  const apiRef = useGridApiRef();
  const tableState = useRef<GridInitialStatePro>();
  const dispatch = useDispatch();
  const dashboardTableState = useSelector(
    (state) => state.indicatorTables.dashboardTable
  );
  const analysisTableState = useSelector(
    (state) => state.indicatorTables.analysisTable
  );

  const isAllActivitiesResult = isAllActivitiesQueryResult(activities);
  const rows = isAllActivitiesResult
    ? activities?.allActivitiesForRevenueAnalysis
    : activities?.dashboardRevenueAnalysis;

  const columns = defaultColumns.slice();
  !isAllActivitiesResult && columns.splice(-1, 0, statusColumn);

  return (
    <MuixTable
      apiRef={apiRef}
      onStateChange={() => {
        const currentState = apiRef.current.exportState();
        delete currentState.columns;
        if (!isEqual(tableState.current, currentState)) {
          tableState.current = currentState;
          if (queryHook === useDashboardRevenueAnalysisQuery) {
            dispatch(setDashboardTable(currentState));
          } else {
            dispatch(setAnalysisTable(currentState));
          }
        }
      }}
      getRowId={(row) => row.activity.id}
      rows={rows || []}
      columns={columns}
      loading={loading}
      rowSelection={false}
      pageSizeOptions={[10, 15, 25, 50]}
      pagination
      autoHeight
      ignoreDiacritics
      disableColumnReorder
      density={isAllActivitiesResult ? 'compact' : 'comfortable'}
      initialState={
        queryHook === useDashboardRevenueAnalysisQuery
          ? dashboardTableState
          : analysisTableState
      }
      onRowClick={({ id }) =>
        history.push({
          pathname: urljoin(urlList.activities, id as string, 'general_info'),
          search: `?origin=${history.location.pathname}`,
        })
      }
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      sx={{
        '& .MuiDataGrid-cell': {
          ':focus': {
            outline: 'none',
          },
        },
        '& .MuiDataGrid-columnHeader': {
          ':focus , :focus-within': {
            outline: 'none',
          },
        },
        '.MuiDataGrid-row:hover': {
          cursor: 'pointer',
          backgroundColor: 'primary.light',
        },
        '.MuiDataGrid-row.Mui-hovered': {
          backgroundColor: 'primary.light',
        },
        borderRadius: '8px',
      }}
    />
  );
}
