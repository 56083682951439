import PolyAlert from 'components/commons/PolyAlert';
import _ from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { SubMissionType } from './BillForm';

interface BillErrorsProps {
  subMissions: SubMissionType[];
}

export default function BillErrors({
  subMissions,
}: BillErrorsProps): ReactElement {
  const { errors, setError, clearErrors, watch } = useFormContext();
  const hasProvisionBills = watch('provisionBills')?.length > 0;
  const isSubMission = !_.isEmpty(subMissions);
  const smAdrs = _.flatMap(subMissions, (sm) => sm?.collaborators || []);

  useEffect(() => {
    if (
      isSubMission &&
      _.isEmpty(smAdrs) &&
      !errors.smAdrs &&
      !hasProvisionBills
    ) {
      setError('smAdrs', { type: 'missingCollaborator' });
    } else if ((!_.isEmpty(smAdrs) || hasProvisionBills) && errors.smAdrs) {
      clearErrors('smAdrs');
    }
  }, [clearErrors, errors, isSubMission, setError, smAdrs, hasProvisionBills]);

  return (
    <>
      {errors.smAdrs?.type === 'missingCollaborator' && (
        <PolyAlert
          variant="outlined"
          severity={'error'}
          key={'smMissingCollaborator'}
          sx={{ mt: 2 }}
        >
          Veuillez ajouter un collaborateur
        </PolyAlert>
      )}
    </>
  );
}
