import AppBar from '@mui/material/AppBar';
import React from 'react';

import { useNavbarContextProvider } from '../DesktopNavbar/NavbarContextProvider';
import SearchMissionBar from '.';

export const TOP_HORIZONTAL_SEARCH_BAR_HEIGHT = '54px';

export default function TopHorizontalSearchBar() {
  const { drawerWidth } = useNavbarContextProvider();
  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        width: `calc(100% - ${drawerWidth})`,
        left: drawerWidth,
        height: TOP_HORIZONTAL_SEARCH_BAR_HEIGHT,
        mt: 2.25,
        backgroundColor: 'white',
        zIndex: 2,
      }}
    >
      <SearchMissionBar />
    </AppBar>
  );
}
