import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TableCell } from '@mui/material';
import clsx from 'clsx';
import PurchaseOrderModal from 'components/commons/PurchaseOrderModal';
import { PoFormMode } from 'components/commons/PurchaseOrderModal/PurchaseOrderForm';
import CollapsePolyTableCell from 'components/MUIOverload/PolyTableCell/CollapsePolyTableCell';
import ChildCollapsePolyTableRow from 'components/MUIOverload/PolyTableRow/ChildCollapsePolyTableRow';
import TransitionPolyTableRow from 'components/MUIOverload/PolyTableRow/TransitionPolyTableRow';
import PolyTooltip from 'components/MUIOverload/PolyTooltip';
import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import CompensationRow from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/CompensationRow';
import MonthRevenueInputCells from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/MonthRevenueInputCells';
import PoTotalRow from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/PoTotalRow';
import TaskRow from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueRows/TaskRow';
import collapseStyles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/CollapseStyles.module.scss';
import styles from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/styles/TableCell.module.scss';
import { useUserInfo } from 'components/User/UserProvider';
import {
  ActivitiesActivityBillingTypeChoices,
  ActivityNode,
  EmployeeNode,
  PurchaseOrderNode,
} from 'generated/graphql';
import _ from 'lodash';
import { totalFormat } from 'pages/ActivityPage/utils';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { TransitionStatus } from 'react-transition-group/Transition';
import { setCurrentPurchaseOrder } from 'store/purchaseOrder';
import { isChiefOrDirectorOfActivity } from 'utils';

interface PoRowProps {
  purchaseOrder: PurchaseOrderNode;
  status: TransitionStatus;
}

export enum PoMode {
  SIMPLE, // package mission --> "po" rows
  TASK, // package mission --> "task po" rows
}

export const getOldRevenuePurchaseOrder = (
  purchaseOrder: PurchaseOrderNode
) => {
  return _(purchaseOrder.revenues)
    .filter((revenue) => revenue.validated)
    .sumBy('amount');
};

export const getPoMode = (
  activity: ActivityNode,
  purchaseOrder: PurchaseOrderNode
) => {
  if (activity.billingType === ActivitiesActivityBillingTypeChoices.Tm) {
    return PoMode.SIMPLE;
  }
  return (purchaseOrder.tasks?.length || 0) > 0 ? PoMode.TASK : PoMode.SIMPLE;
};

const PoRow = ({ purchaseOrder, status }: PoRowProps) => {
  const { activity, displayedMonths } = useTableContext();
  const { employee, isControl, isSuperuser } = useUserInfo();
  const [mode] = useState(getPoMode(activity, purchaseOrder));
  const isActivityChiefOrDirector =
    isChiefOrDirectorOfActivity(activity, employee as EmployeeNode) ||
    activity.director?.id === employee?.id;
  const isSimple = mode === PoMode.SIMPLE;
  const isTask = mode === PoMode.TASK;
  const totalPo = purchaseOrder.total || Infinity;
  const oldRevenue = getOldRevenuePurchaseOrder(purchaseOrder);
  const history = useHistory();
  const dispatch = useDispatch();

  const canAccessLink =
    activity.chiefs && activity.director && employee
      ? isActivityChiefOrDirector || isControl || isSuperuser
      : false;

  const { compensations, revenues } = purchaseOrder;
  const [openCollapse, setOpenCollapse] = useState(
    _.some(compensations, (compensation) => compensation.amount !== 0) ||
      _.some(revenues, (revenue) => revenue.amount !== 0 && revenue.task)
  );

  const [editModalVisibility, setEditModalVisibility] = useState(false);

  const purchaseOrderHref = history.createHref({
    pathname: `/mission-followup/missions/details/purchase_order`,
    search: `?po_id=${purchaseOrder.id}&mission_id=${activity.id}`,
  });

  const getSubRows = (status: TransitionStatus) => {
    if (isTask) {
      return _.map(purchaseOrder.tasks, (task) => {
        const key = `collab-po-row-${purchaseOrder.id}-${task.id}`;
        const isHidden =
          _.first(displayedMonths)?.isAfter(task.periodEnding, 'month') ||
          _.last(displayedMonths)?.isBefore(task.periodBeginning, 'month');
        return (
          <TransitionPolyTableRow key={key} open={!isHidden}>
            {(taskStatus) => (
              <TaskRow
                isTmContracts={
                  activity.billingType ===
                  ActivitiesActivityBillingTypeChoices.Tm
                }
                purchaseOrder={purchaseOrder}
                task={task}
                status={taskStatus === 'exiting' ? taskStatus : status}
              />
            )}
          </TransitionPolyTableRow>
        );
      });
    }
    return <React.Fragment />;
  };

  const totalCell = purchaseOrder.total ? (
    <span>{totalFormat(purchaseOrder.total, purchaseOrder.currency)}</span>
  ) : (
    <span>Non renseigné</span>
  );

  return (
    <>
      <ChildCollapsePolyTableRow status={status}>
        <CollapsePolyTableCell
          className={styles.bdcNameCell}
          open={openCollapse}
          setOpen={setOpenCollapse}
        >
          <div className={collapseStyles.collapseContainer}>
            <div className={collapseStyles.nameCellContainer}>
              {canAccessLink && (
                <Link to={purchaseOrderHref} className={styles.link}>
                  <PolyTooltip title={purchaseOrder.name}>
                    <div>{purchaseOrder.name}</div>
                  </PolyTooltip>
                </Link>
              )}
              {!canAccessLink && (
                <div className={styles.poName}>{purchaseOrder.name}</div>
              )}
            </div>

            <span className={styles.actionsContainer}>
              {(isActivityChiefOrDirector || isControl || isSuperuser) && (
                <IconButton
                  size="small"
                  onClick={() => {
                    dispatch(setCurrentPurchaseOrder(purchaseOrder));
                    setEditModalVisibility(true);
                  }}
                  className={styles.actionButton}
                >
                  <EditIcon />
                </IconButton>
              )}
            </span>
          </div>
        </CollapsePolyTableCell>
        <PurchaseOrderModal
          purchaseOrderId={purchaseOrder.id}
          closeModal={() => setEditModalVisibility(!editModalVisibility)}
          open={editModalVisibility}
          mode={PoFormMode.EDIT}
        />
        <TableCell className={clsx(styles.totalColumn)}>{totalCell}</TableCell>
        {isSimple ? (
          <MonthRevenueInputCells
            isTmContracts={
              activity.billingType === ActivitiesActivityBillingTypeChoices.Tm
            }
            keyForm={`revenuesByPo.${purchaseOrder.id}.revenues`}
            period={{
              start: purchaseOrder.periodBeginning,
              end: purchaseOrder.periodEnding,
            }}
            purchaseOrder={purchaseOrder}
            total={totalPo}
            oldRevenue={oldRevenue}
            isSimple
          />
        ) : (
          <PoTotalRow purchaseOrder={purchaseOrder} />
        )}
      </ChildCollapsePolyTableRow>
      <TransitionPolyTableRow open={openCollapse}>
        {(status) => {
          return (
            <React.Fragment>
              {getSubRows(status)}
              <CompensationRow purchaseOrder={purchaseOrder} status={status} />
            </React.Fragment>
          );
        }}
      </TransitionPolyTableRow>
    </>
  );
};

export default PoRow;
