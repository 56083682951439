import { useTableContext } from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/context/TableContextProvider';
import RevenueFormTableCell from 'components/Revenue/ActualRevenueTables/RevenueMissionTable/RevenueTableCells/RevenueFormTableCell';
import {
  BillingPurchaseOrderCurrencyChoices,
  PurchaseOrderNode,
} from 'generated/graphql';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { comaStringToFloat } from 'pages/ActivityPage/utils';
import { POLY_DATE_MONTH } from 'poly-constants';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface MonthRevenueInputCellsProps {
  purchaseOrder: PurchaseOrderNode;
  period: { start: Moment; end: Moment };
  total: number;
  oldRevenue: number;
  keyForm: string;
  isSimple?: boolean;
  isTmContracts?: boolean;
}
const MonthRevenueInputCells = ({
  purchaseOrder,
  period,
  total,
  oldRevenue,
  keyForm,
  isSimple,
  isTmContracts,
}: MonthRevenueInputCellsProps) => {
  const { displayedMonths } = useTableContext();
  const { watch } = useFormContext();

  const periodMonthNumber =
    moment(period.end).diff(moment(period.start), 'months') + 1;

  const inputRevenues = watch(keyForm);

  const totalInputRevenue = () => {
    return _(inputRevenues)
      .map((input) =>
        comaStringToFloat(!input.validated ? input.amount || 0 : 0)
      )
      .sum();
  };

  const isMonthInPeriodRange = (month: Moment) => {
    const { start, end } = period;
    return (
      month.isSameOrAfter(start, 'month') && month.isSameOrBefore(end, 'month')
    );
  };

  const numberOfFieldWithValues = () => {
    return _.reduce(
      inputRevenues,
      (accumulator, n, keyMonth) => {
        const month = moment(keyMonth, POLY_DATE_MONTH);
        if (isMonthInPeriodRange(month)) {
          return (accumulator += !n.validated && n.amount ? 1 : 0);
        } else {
          return accumulator;
        }
      },
      0
    );
  };

  const getNumberOfCellsValidated = () => {
    return _.reduce(
      inputRevenues,
      (accumulator, n, keyMonth) => {
        const month = moment(keyMonth, POLY_DATE_MONTH);
        if (isMonthInPeriodRange(month)) {
          return (accumulator += n.validated ? 1 : 0);
        } else {
          return accumulator;
        }
      },
      0
    );
  };

  const totalRemain = total - oldRevenue;
  const totalAvailable = totalRemain - totalInputRevenue();

  function getTotalWorkDay(remainingMonthsCount: number) {
    let total_work_day = 0;
    const purchaseOrderEnding = moment(purchaseOrder.periodEnding);
    purchaseOrderEnding.subtract(remainingMonthsCount, 'months');
    const formattedDateStr = purchaseOrderEnding.format('YYYY-MM-DD');

    if (purchaseOrder.monthWorkDays) {
      for (const monthWorkDays of purchaseOrder.monthWorkDays) {
        const currentMonth = monthWorkDays.month;
        if (
          currentMonth >= formattedDateStr &&
          currentMonth <= purchaseOrder.periodEnding
        ) {
          total_work_day += monthWorkDays.workDays;
        }
      }
    }

    return total_work_day;
  }

  function getWorkDayForMatchingMonth(month: Moment) {
    let workDays = null;
    if (purchaseOrder.monthWorkDays) {
      workDays = purchaseOrder.monthWorkDays
        ?.filter(
          (obj) =>
            obj.month === moment(month).startOf('month').format('YYYY-MM-DD')
        )
        .map((obj) => obj.workDays);
    }
    if (workDays) {
      return workDays[0];
    }
    return 0;
  }

  const suggestFill = (month: Moment) => {
    const cellsRemain =
      periodMonthNumber -
      (numberOfFieldWithValues() + getNumberOfCellsValidated());
    if (!isTmContracts) {
      return cellsRemain !== 0 ? totalAvailable / cellsRemain : totalAvailable;
    }
    const total_work_day = getTotalWorkDay(cellsRemain);
    const coef = totalAvailable / total_work_day;
    const workDayForMatchingMonth = getWorkDayForMatchingMonth(month);
    return cellsRemain !== 0 ? coef * workDayForMatchingMonth : totalAvailable;
  };

  const hasSuggestion = !!purchaseOrder.total;

  return (
    <>
      {_.map(displayedMonths, (month) => {
        const isEur =
          purchaseOrder.currency === BillingPurchaseOrderCurrencyChoices.Eur;
        return (
          <RevenueFormTableCell
            purchaseOrder={purchaseOrder}
            period={period}
            month={month}
            keyForm={keyForm}
            totalRow={total}
            suggestedValue={
              hasSuggestion && isEur ? suggestFill(month) : undefined
            }
            key={`${purchaseOrder.id}-${month}-tc`}
            isSimple={isSimple}
          />
        );
      })}
    </>
  );
};

export default MonthRevenueInputCells;
